$block: 'timeline';

.#{$block} {
    // Colors
    $mtkRed: #c61f00;
    $white: #fff;
    $lightGray:  #e5e5e5;
    $darkGray: #3f3f3f;
    $darkGrayLighter: #4e4e4e;

    // Timeline
    $timelineWidth: 4px;
    $timelinePositionLeftXs: 45px;
    $timelinePositionLeftLg: 80px;
    $timelinePaddingLeftXs: 60px;
    $timelinePaddingLeftLg: 80px;
    $timelineColor: $darkGrayLighter;
    $timelineBackgroundColor: $white;
    $timelineMarkerColor: $mtkRed;
    $timelineTransition: all 0.1s linear;
    $timelineTransition: all 0.1s linear;
    $timelineArrowWidth: 10px;
    $timelineArrowHeight: 20px;
    $mileStoneSectionPadding: 20px;
    $mileStoneSectionBorderWidth: 1px;

    margin: 0 0 4em 0;
    background-color: $timelineBackgroundColor;

    &__intro {
        margin: 0 0 3em;
    }

    &__title {
        margin: 0 0 0.5em;
    }

    &__intro-text {
        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &__content {
        position: relative;
        padding: 4em 0 6em ($timelineWidth + $timelinePositionLeftLg + $timelinePaddingLeftLg);

        @media only screen and (max-width: 800px) {
            padding-left: $timelineWidth + $timelinePositionLeftXs + $timelinePaddingLeftXs;
        }
    }

    &__bar {
        position: absolute;
        top: $timelineArrowHeight;
        left: $timelinePositionLeftLg;
        width: $timelineWidth;
        height: calc(100% - #{$timelineArrowHeight});
        background-color: $timelineColor;

        @media only screen and (max-width: 800px) {
            left: $timelinePositionLeftXs;
        }

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: calc(100% - 1px); // -1px because sometimes browsers render a 1px gap between the bar and the arrow
            border-right: $timelineArrowWidth solid transparent;
            border-bottom: $timelineArrowHeight solid $timelineColor;
            border-left: $timelineArrowWidth solid transparent;
            transform: translateX(-50%);
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    &__item {
        display: block;
        margin: 0 0 6em;

        &:last-child {
            margin-bottom: 0;
        }

        &--completed,
        &--in-progress {
            .#{$block} {
                &__year-marker {
                    border-color: $mtkRed !important;
                    background-color: $mtkRed;
                }

                &__year-text {
                    color: $white;
                }
            }
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
    }

    &__year {
        order: 1;
        position: relative;
        margin: 0;

        span {
            display: inline;
            padding: 0;
            border-bottom: 0;
        }
    }

    &__year-marker,
    &__year-text {
        position: absolute;
        left: -$timelinePaddingLeftLg - ($timelineWidth / 2);
        top: 50%;
        transform: translate(-50%, -50%);
        transition: $timelineTransition;

        @media only screen and (max-width: 800px) {
            left: -$timelinePaddingLeftXs - ($timelineWidth / 2);
        }
    }

    &__year-marker {
        $yearMarkerSize: 3em;
        $borderWidth: 2px;

        display: block;
        width: $yearMarkerSize;
        height: $yearMarkerSize;
        border: 2px solid $timelineColor !important;
        border-radius: 50%;
        background-color: $lightGray;
      
        &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(100% + #{$borderWidth});
            width: $timelineWidth * 2;
            height: $timelineArrowHeight / 2;
            background-color: $timelineBackgroundColor;
            transform: translateX(-50%);
        }

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(100% + #{$borderWidth / 2});
            border-right: $timelineArrowWidth solid transparent;
            border-bottom: $timelineArrowHeight solid $timelineColor;
            border-left: $timelineArrowWidth solid transparent;
            transform: translateX(-50%);
        }
    }

    &__year-text {
        font-size: 0.9em;
    }

    &__milestone-list {
        padding: 0 0 3.5em;
        margin: 0;
        list-style-type: none;
    }

    &__milestone-item {
        margin: 0 0 4em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__milestone-section {
        // display: inline-block;
        transition: $timelineTransition;

        &:hover {
            background-color: lighten($lightGray, 6%);

            .#{$block} {
                &__milestone-marker {
                    background-color: $timelineMarkerColor;

                    > .fa {
                        color: $white;
                    }
                }
            }
        }

        &--details-expanded {
            .#{$block} {
                &__milestone-show-more-btn {
                    &::before {
                        @include fontawesome("\f068");
                    }
                }
            }
        }
    }

    &__milestone-section-head {
        position: relative;
        padding: ($mileStoneSectionPadding / 2) ($mileStoneSectionPadding * 2) ($mileStoneSectionPadding / 2) $mileStoneSectionPadding;
        background-color: $darkGrayLighter;
    }

    &__milestone-section-body {
        padding: $mileStoneSectionPadding;
        border: $mileStoneSectionBorderWidth solid $lightGray;
        border-top: 0;
    }

    &__milestone-title {
        position: relative;
        margin: 0;
        transition: $timelineTransition;

        &::before {
            $arrowWidth: 12px;
            $arrowHeight: 8px;

            content: "";
            position: absolute;
            top: 50%;
            display: block;
            right: calc(100% + #{$mileStoneSectionPadding});
            border-top: $arrowHeight solid transparent;
            border-right: $arrowWidth solid $darkGrayLighter;
            border-bottom: $arrowHeight solid transparent;
            transform: translateY(-50%);
        }
    }

    &__milestone-show-more-btn {
        position: absolute;
        right: $mileStoneSectionPadding;
        top: 51%;
        height: auto;
        padding: 0.2em;
        border: 0;
        background: transparent;
        box-shadow: none;
        color: $white;
        text-shadow: none;
        line-height: 1;
        transform: translateY(-50%);

        @media only screen and (max-width: 800px) {
            height: 100%;
            right: calc(#{$mileStoneSectionPadding} - 0.5em);
            padding: 0 0.5em;
        }

        &:hover,
        &:focus {
            background: transparent;
        }

        &:focus {
            outline: 0;
        }

        &::before {
            @include fontawesome("\f067");
        }
    }

    &__milestone-marker {
        $size: 1.5em;
        $borderWidth: 2px;

        position: absolute;
        left: -$timelinePaddingLeftLg - $mileStoneSectionPadding - ($timelineWidth / 2);
        top: 50%;
        width: calc(#{$size} - #{2 * $borderWidth});
        height: calc(#{$size} - #{2 * $borderWidth});
        border: $borderWidth solid $timelineMarkerColor;
        border-radius: 50%;
        background-color: $white;
        transform: translate(-50%, -50%);
        transition: $timelineTransition;
        
        @media only screen and (max-width: 800px) {
            left: -$timelinePaddingLeftXs - $mileStoneSectionPadding - ($timelineWidth / 2);
        }

        > .fa {
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 0.7em;
            transform: translate(-50%, -50%);
            transition: $timelineTransition;
        }
    }

    &__milestone-title-text {
        font-size: 0.95em;
        color: $white;
    }

    &__milestone-text,
    &__milestone-details {
        p {
            margin: 0 0 0.25em;
        }

        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &__milestone-text {
        strong {
            font-weight: normal;
        }
    }

    &__milestone-details-container {
        max-height: 0;
        overflow: hidden;
        transition: all 0.25s ease-out;
    }

    &__milestone-details {
        padding: 1em 0 0;
    }
}
