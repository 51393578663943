﻿@mixin arrowfont {
    font-family: FontAwesome;
} 

@mixin sliderbuttons {
    font-family: "Varela Round", sans-serif;
}

@mixin toggle-arrow {
    position: absolute;
    z-index: 2;
    width: 24px; 
    height: 24px;
    margin-left: -30px;
    @include arrowfont;
    color: $greycolor;
    margin: auto;
    top: 0; 
    bottom: 0;
    left: 10px;
}

@mixin toggle-arrow-events {
    position: absolute;
    z-index: 2;
    width: 24px; 
    height: 14px;
    margin-left: -30px;
    @include arrowfont;
    color: $greycolor;
    margin: auto;
    top: 0; 
    bottom: 0;
    left: 10px;
}