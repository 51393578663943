﻿//----------------
//## Overview Site
$colorblack: #000000;
$colorgrey: #808080;
$colorwhite: rgb(255,255,255);  
$regularfontsize: 12px;
$headlinetwo: 15px; 
$solidonepx: solid 1px;
$bluecolor: #06799f; 
$margintopelement: 20px 0 0 0;
$padding10px: 10px; 
$inputresponsive: 100%;
$paginationborder: #ddd;
$schoolmargin: 20px;

//----------------
//## Details
$onepxsolid: 1px solid;
$greycolor: rgb(100, 100, 100);
$radius: 4px;
