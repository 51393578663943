$color-living: #C61F00;
$color-area: #B55E00;
$color-tourism: #06799F;
$color-economy: #009131;

// Contrast save colors
$color-living-hover: #9B1800;
$color-tourism-hover: #034A61;
$color-area-hover: #964E00;
$color-economy-hover: #007227;

$color-org: #FF2700;

$mtk-blue: #003369;
$ocean-blue: #0079F2;
$mtk-red: #FF1A00;
$apple-green: #008662;
$mtk-gray: #4C5959;

$mtk-blue-hover: #02276b;
$ocean-blue-hover: #016cd6;
$mtk-red-hover: #de1a05;
$apple-green-hover: #44dc02;
$mtk-gray-hover: #323a3a;



.noBackgroundColor {
  background-color: transparent !important; }

.dh1 {
	margin: 1em 0 0.5em !important;
	color: #3f3f3f !important;
	font: 400 2.308em "Signika",sans-serif !important; 
}

.dh1:first-child {
	margin: 1em 0 0.3em !important;
	font-size: 2.615em !important; 
}

.dh2 {
	margin: 1em 0 0.5em !important;
	color: #3f3f3f !important;
	font: 300 1.8em "Signika",sans-serif !important;
}

.dh2 span {
	display: inline-block !important;
	margin-bottom: -1px !important;
	padding: 8px 0 !important;
	border-bottom: 2px solid #FF2700 !important; 
}

.dh3 {
	margin: 0 0 0.5em !important; /* old val 1.5 0 0.5em !important */
	color: #3f3f3f !important;
	font: 300 1.6em "Signika",sans-serif !important; 
}

.dh3 span {
	display: inline-block !important;
	margin-bottom: -1px !important;
	padding: 8px 0 !important;
	border-bottom: 2px solid $color-area !important; 
}

.dh4 {
	margin: 1.5em 0 1em !important;
	color: #3f3f3f !important;
	font: 300 1.538em "Signika",sans-serif !important; 
}

.dh5 {
	margin: 1.5em 0 1em !important;
	color: #3f3f3f !important;
	font: 300 1.385em "Signika",sans-serif !important; 
}

.dh6 {
	margin: 1.5em 0 1em !important;
	color: #3f3f3f !important;
	font: 300 1.231em "Signika",sans-serif !important; 
}

address {
	font-weight: normal;
	font-style: normal; 
}

address a {
	color: #aa2108;
	font-weight: bold; 
}

address a:hover {
	color: #aa2108;
	text-decoration: underline; 
}

// Overwriting Default
.bold-hdl {
    margin: 1em 0 0.3em;
    font-size: 2.615em;
}

.h5 section.top {
    text-align: left;
    height: 37px;
    font-size: 1em;

    div {
        height: 37px;
    }

    .searchform {
        float: right;
        display: inline-block;
        margin-top: 5px;
        position: relative;
    }
}

.h5 .top p {
    line-height: 37px;
}

.h5 > section.top nav > ul {
    height: 37px;
}

.h5 > section.top nav > ul > li > a {
    height: 37px;
    line-height: 37px;
}

@media (min-width: 801px) {

/*S Fixed Header modifications*/
  .color-red header {
    position: fixed;
    width: 1000px; }
  .breadcrumb {
    border-bottom: 1px solid #e6e6e6;
    background-color: #ececec;
    margin-top: 150px; }
  .slider4 {
    margin-top: 150px; }

  .searchResult {
    position: relative;
    overflow: hidden;
    clear: both;
    max-width: 940px;
    margin: 0 auto;
    padding: 1px 0 40px;
    margin-top: 150px;
     }

  /*E Fixed Header modifications*/


    // 55 will de deckers
    header.sticky img {
        height: 55px;
        width: auto;
    }

    header.sticky .social-links {
        display: none;
    }

    header .title img {
        vertical-align: top;
    }

    header.h1.sticky.no-topbar, header.h2.sticky.no-topbar, header.h3.sticky.no-topbar, header.h4.sticky.no-topbar, header.h5.sticky.no-topbar, header.h6.sticky.no-topbar, header.h7.sticky.no-topbar, header.h11.sticky.no-topbar, header.h12.sticky.no-topbar, header.h13.sticky.no-topbar, header.h14.sticky.no-topbar, header.h15.sticky.no-topbar {
        height: 80px;
    }

    header.sticky nav.mainmenu {
        margin-top: 26px;
    }

    .color-red .main-header a:hover {
        color: #3f3f3f !important;
    }

    header.sticky .title {
        margin: 5px 0;
    }
}

@media (max-width: 800px) {

    header nav.social > ul {
        display: block;
        height: 30px;
        margin: 15px 0;
        padding: 0;
        line-height: 24px;
    }

    header nav.utils > ul {
        display: inline;
    }

    header.h5 .top {
        padding: 0 20px;
        margin-bottom: 10px;
    }

    header > section.top .searchform {
        margin-top: 8px;

		input {
			color: #353535;
		}
    }
}

@media (max-width: 800px) {
    header nav.social > ul {
        margin: 8px 0;
    }
}

header > section.top > div {
    position: relative;
}

#toggle-search {
    display: none;
}

@media (max-width: 430px) {
    header > section.top .searchform {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 220px;
        opacity: 0;
    }

    #toggle-search {
        display: block;
        position: absolute;
        top: 5px;
        right: 2px;
        cursor: pointer;
        color: #e94141;
    }

	.h5 {
		 section.top {
			 .searchform {
				 margin-top: 2px;
			 }
		 }
	}
}

header .title {
    margin: 10px 0;
}

.color-red .searchform button,
.color-red .searchform button:hover {
    background: none;
    text-shadow: none;
    color: #747474;
}

// search color settings
.submit input#submit.living {
    background-color: $mtk-red;

    &:hover {
        background-color: $mtk-red-hover;
    }
}

.submit input#submit.area {
    background-color: $mtk-blue;

    &:hover {
        background-color: $mtk-blue-hover;
    }
}

.submit input#submit.tourism {
    background-color: $apple-green;

    &:hover {
        background-color: $apple-green;
    }
}

.submit input#submit.economy {
    background-color: $mtk-gray;

    &:hover {
        background-color: $mtk-gray-hover;
    }
}

.menustyles .eventbutton, .mapLink, .right div.details a.logo {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
}

.menustyles {
	.eventbutton {
		&:focus {
			border: 2px solid;
			background-color: transparent !important;
		}
	}

	.eventbutton, .mapLink, .right  {
		> span {
			background-color: transparent !important;
		}

		> span a,
		div.details a.logo {
			&:focus {
				padding: 4px !important;
				border: 2px solid;
				background-color: transparent !important;
			}
		}
	}
}

.menustyles.living {
	.eventbutton {
		&:focus {
			border-color: $mtk-red;
		}
	}

    .eventbutton, .mapLink, .right div.details a.logo {
        background-color: $mtk-red;

        &:hover {
            background-color: $mtk-red-hover;
        }

		&:focus {
			border-color: $mtk-red;
			color: black !important;
		}
    }
}

.menustyles.area {
	.eventbutton {
		&:focus {
			border-color: $mtk-blue;
		}
	}

    .eventbutton, .mapLink, .right div.details a.logo {
        background-color: $color-area;

        &:hover {
            background-color: $color-area-hover;
        }

		&:focus {
			border-color: $color-area;
			color: black !important;
		}
    }
}

.menustyles.tourism {
	.eventbutton {
		&:focus {
			border-color: $apple-green;
		}
	}

    .eventbutton, .mapLink, .right div.details a.logo {
        background-color: $apple-green;

        &:hover {
            background-color: $apple-green-hover;
        }

		&:focus {
			border-color: $apple-green;
			color: black !important;
		}
    }
}

.menustyles.economy {
	.eventbutton {
		&:focus {
			border-color: $mtk-gray;
		}
	}

    .eventbutton, .mapLink, .right div.details a.logo {
        background-color: $mtk-gray;

        &:hover {
            background-color: $mtk-gray-hover;
        }

		&:focus {
			border-color: $mtk-gray;
			color: black !important;
		}
    }
}



// Mobile Navigation
#mobile-nav {
    display: none;
}



@media (max-width: 800px) {

    .header-wrapper:after {
        visibility: hidden;
        display: block;
        content: "";
        clear: both;
        height: 0;
    }

    header > section.main-header nav {
        float: none;
    }

    header > section.main-header nav.social {
        float: right;
        clear: none;
    }

    #mobile-nav {
        /*margin: 0 -40px;*/
        display: block;
        font: 1.231em "Signika",sans-serif;

        ul {
            text-align: left;

            li {
				padding: 1em 0;
                margin: 0;
                position: relative;


                i.fa {
                    position: absolute;
                    top: 50%;
                    margin-top: -8px;
                    right: 0;
                }

                &.living {
                    //background: $color-living !important;
                    border-left: 6px solid $mtk-red;
                    border-top: 6px solid $mtk-red;
                    border-right: 6px solid $mtk-red;

                    ul {
                        //   border-left: 6px solid $color-living;
                        li {
                            border-top: 1px solid $mtk-red;
                        }
                    }
                }

                &.area {
                    // background: $color-area !important;
                    border-left: 6px solid $mtk-blue;
                    border-top: 6px solid $mtk-blue;
                    border-right: 6px solid $mtk-blue;

                    ul {
                        // border-left: 6px solid $color-area;
                        li {
                            border-top: 1px solid $mtk-blue;
                        }
                    }
                }

                &.tourism {
                    // background: $color-tourism !important;
                    border-left: 6px solid $apple-green;
                    border-top: 6px solid $apple-green;
                    border-right: 6px solid $apple-green;

                    ul {
                        //border-left: 6px solid $color-tourism;
                        li {
                            border-top: 1px solid $apple-green;
                        }
                    }
                }

                &.economy {
                    //background: $color-economy !important;
                    border-left: 6px solid $mtk-gray;
                    border-top: 6px solid $mtk-gray;
                    border-right: 6px solid $mtk-gray;

					ul {
                        //border-left: 6px solid $color-economy;
                        li {
                            border-top: 1px solid $mtk-gray;
                        }
                    }
                }

                a {
                    color: #3f3f3f;
                    display: block;
                    position: relative;

                    &:after {
                        visibility: hidden;
                        display: block;
                        content: "";
                        clear: both;
                        height: 0;
                    }
                }

                ul {
                    display: none;
                    background: white;
                    margin: 1em -2em -1em -2em;

                    li {
                        padding: 1em 2em 1em 2em;

                        a {
                            color: #3f3f3f;
                            padding: 0;
                        }
                    }
                }

                &.current-menu-item > ul {
                    display: block;
                }
            }
        }

		> ul {
			> li {
				padding: 1em 2em;
			}
		}
    }
}

@media (max-width: 420px) {
    header > section.main-header nav.social {
        display: none;
    }
}

@media (max-width: 400px) {
    header .title {
        padding: 0 10px;
    }
}



// Custom Styles
header.h5 {
    border-bottom: 6px solid $color-living;

    &.living {
        border-bottom: 6px solid $mtk-red;
    }

    &.area {
        border-bottom: 6px solid $mtk-blue;
    }

    &.tourism {
        border-bottom: 6px solid $apple-green;
    }

    &.economy {
        border-bottom: 6px solid $mtk-gray;
    }
}

.mainmenu {
    & > ul > li {
        & {
            margin-left: 3px !important;
        }

        & > a {
            background: #f6f5f5 !important;
            color: #3f3f3f !important;
            margin-top: -4px;
        }

        & > a.living {
            // background: $color-living !important;
            border-top: 4px $mtk-red solid !important;

            &:hover {
                background: $mtk-red !important;
                color: white !important;
            }
        }


        & > a.area {
            // background: $color-area !important;
            border-top: 4px $mtk-blue solid !important;

            &:hover {
                background: $mtk-blue !important;
                color: white !important;
            }
        }

        & > a.tourism {
            // background: $color-tourism !important;
            border-top: 4px $apple-green solid !important;

            &:hover {
                background: $apple-green !important;
                color: white !important;
            }
        }

        & > a.economy {
            // background: $color-economy !important;
            border-top: 4px $mtk-gray solid !important;

            &:hover {
                background: $mtk-gray !important;
                color: white !important;
            }
        }

        &.current-menu-item {
            & > a.living {
                border-bottom: 1px solid $mtk-red !important;
                background: $mtk-red !important;
                color: white !important;
            }

            & > a.area {
                border-bottom: 1px solid $mtk-blue !important;
                background: $mtk-blue !important;
                color: white !important;
            }

            & > a.tourism {
                border-bottom: 1px solid $apple-green !important;
                background: $apple-green !important;
                color: white !important;
            }

            & > a.economy {
                border-bottom: 1px solid $mtk-gray !important;
                background: $mtk-gray !important;
                color: white !important;
            }
        }

        &:hover {
            & > a {
                border-bottom: 1px solid white !important;
            }
        }

        ul {
            li:hover {
                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

header > section.main-header nav {
    margin: 2px 0 0;
}

.boxed header > section.main-header {
    padding-top: 15px;
}

/*Tab-Navigation*/
    .mainmenu > ul > li.hover > ul   {
        display: block;
    }


/*Buttons*/
@mixin makeButton($defColor, $hovColor) {
    background: $hovColor;
    background-image: -webkit-linear-gradient(top, $hovColor, $defColor);
    background-image: -moz-linear-gradient(top, $hovColor, $defColor);
    background-image: -ms-linear-gradient(top, $hovColor, $defColor);
    background-image: -o-linear-gradient(top, $hovColor, $defColor);
    background-image: linear-gradient(to bottom, $hovColor, $defColor);
    text-shadow: none;
    border: 1px solid$hovColor;

    &:hover {
        background: $color-area;
        background-image: -webkit-linear-gradient(top, $defColor, $hovColor);
        background-image: -moz-linear-gradient(top, $defColor, $hovColor);
        background-image: -ms-linear-gradient(top, $defColor, $hovColor);
        background-image: -o-linear-gradient(top, $defColor, $hovColor);
        background-image: linear-gradient(to bottom, $defColor, $hovColor);
        text-decoration: none;
        border: 1px solid $defColor;
    }
}

a {
    &.button {
        &.green {
            //@include makeButton($color-area,$color-area-hover);
            //@include makeButton($color-area,$color-area);
            @include makeButton($color-economy,$color-economy);
        }

        &.blue {
            //@include makeButton($color-economy,$color-economy-hover);
            //@include makeButton($color-economy,$color-economy);
            @include makeButton($mtk-blue,$mtk-blue);
        }

        &.orange {
            //@include makeButton($color-living,$color-living-hover);
            //@include makeButton($color-living,$color-living);
            @include makeButton($color-area,$color-area);
        }

        &.red {
            //@include makeButton($color-tourism,$color-tourism-hover);
            //@include makeButton($color-tourism,$color-tourism);
            @include makeButton($color-living,$color-living);
        }
    }
}


.columns {
    article {
        ul {
            li {
				/* ListElemente Frontpage bzw. in Article*/
                margin-left: 0;
                list-style-type: none;
            }
        }
    }

	.col4 {
		.hdl-dyn-teaser {
			min-height: 3em;
			margin: 0.5em 0;
			border-bottom: none;
			font-size: 1.385em;

			@media (max-width: 400px) {
				min-height: 0;
			}
		}
	}
}

/* Definition Skiplink */
a.skiplink {
	overflow: hidden;
    position: fixed;
    left: 200%;
    top: 200%;
	font-size: 1.5em;
	font-weight: bold;
	
	&:focus,
	&:active {
		left: 0;
		top: 0;
	}
}

/* Ende Skiplink */

.social-links,
.social {
    float: right;
    margin: 0;

    ul {
        float: right;
        overflow: hidden;
        margin: 0;

        li {
            margin: 0 0.3em;
            float: left;
            margin-left: 8px;

            a {
                .label {
                    width: 0;
                    display: inline-block;
                    overflow: hidden;
                }
            }
        }
    }
}

.color-red {
	 footer {
		 a {
			padding: .1em;
			font-weight: normal;
			-webkit-transition-duration: .1s;
			-moz-transition-duration: .1s;
			-ms-transition-duration: .1s;
			-o-transition-duration: .1s;
			transition-duration: .1s;

			&:hover,
			&:focus {
				background-color: #ebebeb;
				color: #333;
			}
		 }

		 section {
			 h2 {
				 margin: 1.5em 0 0.5em;
				
				 &:first-child {
					 span {
						 border-bottom-width: 1px;
					 }
				}
			 }
		 }  

		.social-links {
			ul li a {
				color: #d8d8d8;

				&:hover,
				&:focus {
					background-color: transparent;
					color: #efefef;
				}
			}
		}
	}
}

.meta-links {
	float: left;

	ul {
		margin: 0;

		li {
			margin: 0  0 0;
			display: inline;
			font-size: 0.917em;

			&:not(:last-child):after {
				content: " | ";
			}

			a {
				padding: .2em;
			}
		}
	}
}

header {
    .social {
        height: 49px;

        ul {
            margin: 3px 0;

            li {

                a {
                    color: #949494 !important;
                }

                a:hover {
                    color: #3f3f3f !important;
                }
            }
        }
    }
}

/*header {
    .social-links {
        ul {
            
        }
    }
}*/

/*MedienInfos Teaser*/
.medieninfos-intro {
}

.medieninfos-teaser {
    margin: 0;
}

.medieninfos-img {
    max-width: 49%;
    float: left;
    margin-right: 1em;

    img {
        max-width: 100%;
    }
}

/*Contentslider*/
.contentSlider {
    background-color: #F5F5F5;

    ul {
        li {
            margin: 0;

            .slideWrapper {
                display: table;
                position: relative;
                width: 100%;
                /*padding: 10px 0;*/
                .contentSlider-left {
                    width: 50%;
                    float: left;

                    @media (max-width: 620px) {
                        clear: left;
                        width: 100%;
                    }

                    img {
                        max-width: 100%;
                    }
                }

                .contentSlider-right {
                    margin: 0;
                    width: 50%;
                    float: left;
                    padding-left: 1em;
                    padding-right: 1em;
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    // display: table-cell;
                    // height: 100%;
                    // background-color: #F5F5F5;
                    /* margin-bottom: 100%; */
                    // position: absolute;
                    // right: 0;
                    // top: 10px;
                    .contentslider-right-hdl {
                        margin-top: 0;

                        h4 {
                            margin-top: 0;
                        }
                    }

                    @media (max-width: 620px) {
                        clear: left;
                        width: 100%;
                        padding-left: 0;
                        padding-right: 0;
                        padding-top: 1em;
                        position: relative;
                        right: 0;
                        bottom: 0;
                    }
                }

                .fullWidthSlide {
                    text-align: center;
                    width: 100%;
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;

                    img {
                        max-width: 100%;
                    }

                    .caption {
                        width: 100%;
                        text-align: center;
                        margin-top: 0.5em;
                        margin-bottom: 0;
                    }
                }
            }
        }

		&.fullWidth {
			li figure {
				position: relative; 
				
				img {
					width: 100%; 
				}

				figcaption {
					position: absolute;
					bottom: 0px;
					left: 0px;
					padding: 4px;
					background-color: rgba(255, 255, 255, 0.8); 

					.copyright {
						padding-left: 6px;
					}
				}
			}

		}
    }

    .ContentSldierButtons {
        text-align: center;
        padding: 5px 0;
        border-top: 1px solid rgba(63, 63, 63, 0.2);

        a {
            cursor: pointer;
        }

        a:hover,
        a.pressed {
            border: 1px solid #50a1cf;
            background: white;

            i.fa {
                color: #50a1cf;
            }
        }
    }
}


/*Frontpage*/

.col2.team {
	h1,
	h2 {
		margin-top: 0 !important;
	}
}

.col4.team {
    h3 {
        @media (min-width: 401px) {
            min-height: 45px;
        }
    }
}

a.readmore {
    /*color: #e94141;*/
    &:hover {
        text-decoration: underline;
    }
}

/*MediaTeaser*/
.fp-media-teaser {
    h3 {
        @media (min-width: 401px) {
            min-height: 2.2em;
        }
    }

    .fp-media-teaser-txt {
        min-height: 6em;

        @media (max-width: 400px) {
            min-height: 0;
        }
    }
}
/*Kontaktformular*/
.contact-form {
	.input-error-notification {
		margin: 0 .5em 0 0;
		float: right;
		display: none;
		font-weight: bold;
		color: red;
	}

	.input-error {
		input {
			border: 1px solid red;
		}

		label {
			.input-error-notification {
				display: block;
			}
		}

		label[for='privacyAccepted'] {
			padding: 0 0 0 0;

			.input-error-notification {
				float: none;
			}
		}
	}

    label {
        a {
            text-decoration: underline;
        }

        &.radio {
            width: 15px;
            margin: 0;
            background: none;
            float: left;
            margin-right: 10px;

            &.checked {
                padding-left: 5px;
            }

            input {
                max-width: 100%;
                margin: 0;
                margin-top: 2px;
                margin-bottom: 100%;
                visibility: visible;
                position: static;
            }
        }

        &.checkbox {
            width: 15px;
            margin: 0;
            background: none;
            float: left;
            margin-right: 10px;
            padding-left: 5px;

            &.checked {
                padding-left: 5px;
            }

            input {
                max-width: 100%;
                margin: 0;
                margin-top: 2px;
                margin-bottom: 100%;
                visibility: visible;
                position: static;
            }
        }
    }

    input[type="submit"] {
        width: auto;
        @include makeButton($color-living,$color-living);
        display: inline-block;
        height: 32px;
        padding: 0 20px;
        color: #fff;
        font: bold 1em/32px "Arial",sans-serif;
        box-shadow: 0 2px 2px rgba(0,0,0,0.2);
        text-transform: uppercase;
    }

    span.file-input {
        width: 100%;
        display: inline-block;

        input.fake {
            min-width: 90%;
        }
    }

    .form-devider {
        margin: 15px 0;
    }

    .file-input a.empty {
        z-index: 50;
    }

    select, .select {
        width: 98%;
        max-width: 98%;
        margin: 5px 0;
    }

    textarea {
        width: 92%;
        max-width: 92%;
        min-width: 92%;
        margin: 5px 0;
        min-height: 200px;
    }

    input {
        width: 92%;
        max-width: 92%;
        margin: 5px 0;
    }
}

/*Dolmetscherformular*/
.interpreter-form {
    p:after {
        content: "";
        clear: both;
        display: table;
    }
    span.select {
        width: 51%;
        max-width: 51%;

        &.error {
            border: 1px solid red;
        }
    }

    select {
        width: 100%;
    }

    label, input[type="text"], input[type="email"] {
        width: 45%;
        float: left;
    }

    label.radio input {
        margin: 0;
        padding: 0;
    }

    .interpreter-form-label {
        clear:left;
        display:inline-block;
        margin: 0.5em 0;
        width:100%;
        font-weight:bold;
        color:#777;
        font:300 1.538em "Signika",sans-serif;
    }

    .separator {
        float: left;
        width: 100%;
        height: 100%;
    }

    strong {
        text-decoration: underline;
        &.interpreter-form-headline {
            text-decoration: none;
        }
        &.living {
            color: $mtk-red;
        }
    }

    input[type="submit"] {
        width: auto;
        @include makeButton($color-living,$color-living);
        display: inline-block;
        height: 32px;
        padding: 0 20px;
        color: #fff;
        font: bold 1em/32px "Arial",sans-serif;
        box-shadow: 0 2px 2px rgba(0,0,0,0.2);
        text-transform: uppercase;
        margin: 5px 0;
    }

    .interpreter-form-checkboxes {
        label {
            width:auto;
        }
        label.checkbox {
            width:5%;
        }
    }
    input.button.btn.sending {
        margin-top:2em;
    }
}
/* sitemap */
ul.sitemap {
    list-style-type:disc;
    ul {
        list-style-type: disc;
    }
}
//dave
h1.error span, h2.error span, h3.error span {
    border-bottom-color: $color-living;
}

h1.success span, h2.success span, h3.success span {
    border-bottom-color: $color-economy;
}
/*Bilder im Text*/
.txt-img {
    max-width: 49%;
    margin-top: 0.5em;

    @media (max-width: 400px) {
        max-width: 100%;
    }

    .img-infos {
        font-size: 10px;
        line-height: 12px;
    }

    img {
        max-width: 100%;
    }

    &.right {
        float: right;
        margin-left: 1em;

        @media (max-width: 400px) {
            float: none;
            margin-left: 0;
        }
    }

    &.left {
        float: left;
        margin-right: 1em;

        @media (max-width: 400px) {
            float: none;
            margin-right: 0;
        }
    }
}

/*Related Content*/
.related-content-post {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.cat {
    font-size: 1.2em;
    text-align: right;
    margin-bottom: 15px !important;

    span {
        display: inline-block;
        padding: 3px 5px 3px 5px !important;
        border: 0 !important;
    }
}

@mixin search-heads($bgcolor: #c61f00, $bordersize: solid 3px, $fontcolor: #fff) {
    border-bottom: $bordersize $bgcolor;

    span {
        background-color: $bgcolor;
        color: $fontcolor;
    }
}

.head-facilities {
    @include search-heads($bgcolor: #c61f00);
}

.head-living {
    @include search-heads($bgcolor: #ff8400);
}

.head-tourism {
    @include search-heads($bgcolor: #06799f);
}

.head-district {
    @include search-heads($bgcolor: #B55E00);
}

.head-economy {
    @include search-heads($bgcolor: #009131);
}




.search-result-entry {
    display: block;
    position: relative;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    .subcatwrapper {
        width: 85px;
        height: 88px;
        overflow: hidden;
        position: absolute;
        top: -3px;
        right: -3px;

        .subcat {
            font: bold 12px Sans-Serif;
            color: #333;
            text-align: center;
            text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            position: relative;
            padding: 9px 0;
            left: -5px;
            top: 15px;
            width: 120px;
            background-color: #BFDC7A;
            background-image: -webkit-gradient(linear, left top, left bottom, from(#BFDC7A), to(#8EBF45));
            background-image: -webkit-linear-gradient(top, #BFDC7A, #8EBF45);
            background-image: -moz-linear-gradient(top, #BFDC7A, #8EBF45);
            background-image: -ms-linear-gradient(top, #BFDC7A, #8EBF45);
            background-image: -o-linear-gradient(top, #BFDC7A, #8EBF45);
            color: #6a6340;
            -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
            -moz-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
            box-shadow: 0px 0px 3px rgba(0,0,0,0.3);

            &:before, &:after {
                content: "";
                border-top: 3px solid #6e8900;
                border-left: 3px solid transparent;
                border-right: 3px solid transparent;
                position: absolute;
                bottom: -3px;
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
            }
        }

        @media (max-width: 660px) {
            display: none;
        }
    }
}

.mark {
    font-size: 0.8em;
    font-weight: bold;
    text-shadow: 0 0 5px #fff;
    border-top: dotted 1px lighten(#000, 90%);
    padding: 3px 2% 3px 2%;
    background: darken(whitesmoke, 0);
    overflow: hidden;
    text-align: right;

    span {
        display: inline-block;
        margin-right: 5px;
        padding-right: 14px;
        background: url("../images/bcrumb-arrow.gif") 100% 50% no-repeat;
        color: #3f3f3f;
    }

    p {
        font-size: 0.9em;
        padding: 0;
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

.related-content-post,
.search-result-entry {
    background: whitesmoke;
    padding: 2%;
    margin-top: 15px;

    .living {
        background: $mtk-red;
        color: #fff;
    }

    .area {
        background: $mtk-blue;
        color: #fff;
    }

    .tourism {
        background: $apple-green;
        color: #fff;
    }

    .economy {
        background: $mtk-gray;
        color: #fff;
    }

    .related-content-post-left,
    .search-result-entry-left {
        float: left;
        width: 24%;
        position: relative;
        padding-right: 1%;

        .marker {
            position: absolute;
            z-index: 10;
            padding: 4px;
            bottom: 0;
        }

        @media (max-width: 660px) {
            width: 39%;
        }

        @media (max-width: 480px) {
            width: 100%;
            margin-bottom: 16px;
        }
    }

    .search-result-entry-left {
        margin-right: 10px;
    }

    .related-content-post-right {
        float: right;
        width: 75%;

        @media (max-width: 660px) {
            width: 60%;
        }

        @media (max-width: 480px) {
            width: 100%;
            margin-bottom: 16px;
        }

        h3, p {
            margin-top: 5px;
        }
    }

    .search-result-entry-right {
        &:after {
            content: "";
            display: table;
            clear: both;
        }

        .marker {
            padding: 4px;
            float: right;
        }
    }


    .related-content-post-right,
    .search-result-entry-right {

        h3 {
            margin: 0;
        }
    }

    img {
        width: 100%;
    }
}

/* --- start infoboxes --- */
.infoboxes {
	padding: 2%;
	margin-top: 15px; 

	address {
		font-style: normal; 
	}

	h1, h2, h3, h4, h5, h6 {
		margin-top: 0px !important; 
	}

	.infoboxes-contactInfoLeft {
		float: left;
		padding-right: 2%; 

		img {
			width: 75px; 
		}
	}

	@media (max-width: 480px) {
		.infoboxes-contactInfoLeft {
			width: 100%;
			margin-bottom: 16px; 
		}
	} 

	.infoboxes-contactInfoRight {
		float: left; 
	}
}
/* --- end infoboxes --- */

.maincat .search-result-entry {
    background: darken(whitesmoke, 3%);
}

.search-result-entry-wrap {

    a {
        display: block;


        &:hover {

            .search-result-entry {
                background: darken(whitesmoke, 2.5%);

                .img-border {
                    opacity: 1 !important;
                    -moz-opacity: 1 !important;
                    -khtml-opacity: 1 !important;
                    -webkit-opacity: 1 !important;
                    filter: alpha(opacity=100) !important;
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
                }
            }
        }
    }

    .subsite {
        span {
            float: left;
            display: block;
            padding: 3px 5px 3px 5px;
            margin-bottom: 3px;
        }

        a {
            float: left;
            display: block;
            padding: 3px 5px 3px 5px;
            background: darken(whitesmoke, 3%);
            margin-right: 5px;
            margin-bottom: 3px;


            &:hover {
                background: darken(whitesmoke, 5%);
            }
        }

        &:after {
            visibility: hidden;
            display: block;
            content: "";
            clear: both;
        }
    }
}

//Ehrenamt
.mltpl-add-links {
    width: 100%;
    display: table;

    a {
        display: table-cell;
        /*text-align:center;*/
        @media (max-width: 500px) {
            display: block;
        }
    }
}


//aside Nav
.content > aside > :not(nav) a {
    font-weight: bold;
    color: #aa2108;
}

.content > aside nav.menu > ul ul {
    padding: 0 0 0 10px;
    font: 300 .9em "Signika",sans-serif;
}

.content > aside nav.menu > ul > li {
    position: relative;

    a {
        display: inline-block;
    }

    &.parent {
        position: relative;

        a {
            position: relative;
            background-image: none;
        }

        .fa-chevron-nav {
            position: absolute;
            top: 50%;
            margin-top: -8px; // halbe höhe des elements
            right: 0;
            padding-right: 8px;
            cursor: pointer;

            &:before {
                content: "\f054";
            }
        }

        &.expanded {
            a {
                background-image: none;
            }

            & > .fa-chevron-nav {
                &:before {
                    content: "\f078";
                }
            }
        }

        li {
            position: relative;

            &.parent {
                a {
                    position: relative;
                    background-image: none;
                }

                .fa-chevron-nav {
                    position: absolute;
                    top: 50%;
                    margin-top: -8px; // halbe höhe des elements
                    right: 0;
                    padding-right: 8px;

                    &:before {
                        content: "\f054";
                    }
                }
            }

            &.expanded {
                a {
                    background-image: none;
                }

                & > .fa-chevron-nav {
                    &:before {
                        content: "\f078";
                    }
                }
            }
        }
    }
}

.go-top {
    z-index: 50000;
}


.mtkhidden {
    display: none !important;
}

.mtkhiddenSoft {
    display: none;
}


/*header > section.main-header {
    background-image: url("../images/mtk/header_bg2.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    @media (max-width: 800px) {
        background-position-y: 32px;
    }
}*/
/*.bg-wrapper{
     background-image: url("../images/mtk/header_bg.png");
     background-repeat: no-repeat;
     background-size: 100% auto;
     background-position-y: 5px;
}*/
/*header > section.main-header nav.social {
    ul{
        li{
            a {
                .fa{
                    background-color: white;
                }
            }
        }
    }
}*/
/*.introimage{
    margin:0 -30px -30px -30px;
}*/
.img-responsive {
    max-width: 100%;
}


.columns > h2.stripes:first-child, .home section .columns h2.stripes:first-child, .home section .latest h2.stripes:first-child, .why-us h2.stripes, section h2.stripes:first-child {
    &.living {
        span {
            border-color: $mtk-red;
        }
    }

    &.area {
        span {
            border-color: $mtk-blue;
        }
    }

    &.tourism {
        span {
            border-color: $apple-green;
        }
    }

    &.economy {
        span {
            border-color: $mtk-gray;
        }
    }
}

.color-red .content > aside nav.menu > ul li.current-menu-item > a,
.color-red aside h3 span,
.color-red footer h2 span,
.color-red footer h3 span,
.color-red footer strong span {
	border-color: $color-area; /* default color */

    &.living {
        border-color: $mtk-red;
    }

    &.area {
        border-color: $mtk-blue;
    }

    &.tourism {
        border-color: $apple-green;
    }

    &.economy {
        border-color: $mtk-gray;
    }
}





.color-red a.go-top {

    &.living {
        background-color: $mtk-red;
    }

    &.area {
        background-color: $mtk-blue;
    }

    &.tourism {
        background-color: $apple-green;
    }

    &.economy {
        background-color: $mtk-gray;
    }
}

video {
    width: 100%;
    height: auto;
}

// Cookie Bar
#cookie-bar {
    background: #111111;
    height: auto;
    line-height: 24px;
    color: #eeeeee;
    text-align: center;
    padding: 3px 0;
}

#cookie-bar.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

#cookie-bar.fixed.bottom {
    bottom: 0;
    top: auto;
}

#cookie-bar p {
    margin: 0;
    padding: 0;
}

#cookie-bar a {
    color: #ffffff;
    display: inline-block;
    border-radius: 3px;
    text-decoration: none;
    padding: 0 6px;
    margin-left: 8px;
}

#cookie-bar .cb-enable {
    background: #007700;
}

#cookie-bar .cb-enable:hover {
    background: #009900;
}

#cookie-bar .cb-disable {
    background: #990000;
}

#cookie-bar .cb-disable:hover {
    background: #bb0000;
}

#cookie-bar .cb-policy {
    background: #0033bb;
}

#cookie-bar .cb-policy:hover {
    background: #0055dd;
}

// Social Share

.shariff {
    ul {
        float: right;
        width: auto !important;
    }

    li {
        width: auto !important;
        margin: 0;
        margin-bottom: 8px;
    }

    a:hover {
        color: #fff;
    }
}

.archivefilter {
    display: inline-block;

    p {
        text-align: left;
        margin: 0;
    }

    label {
        display: inline-block;
    }
}

.align-right {
    text-align: right;
}

.publications {

    .select, select {
        width: 100%;
        max-width: 100%;
        margin: 5px 0;
    }

    .filters {
        margin-bottom: 2px;
        font-size: 0;

        p {
            padding-right: 2px;
        }

        .col3 {
            font-size: 13px;
        }
    }

    .filter-buttons {
        margin-top: 2px;
    }

    .filter-result {
		margin-top: 0;
        background-color: #ECECEC;
		font: bold 1.1em/36px Arial, sans-serif;
		text-align: center;
    }

    .result-view {
        &:after, &::after {
            height: 0;
            clear: both;
            content: '';
        }

        .result-wrapper {
            margin-bottom: 30px;

            .result {
                display: inline-block;
                width: 100%;
                padding-bottom: 30px;

                .result-lft {
                    float: left;
                    width: 33%;

                    @media (max-width: 480px) {
                        float: none;
                        width: 100%;
                    }

                    img {
                        max-width: 100%;
                    }
                }

                .result-rgt {
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    padding-left: 1em;
                    float: left;
                    width: 67%;

                    @media (max-width: 480px) {
                        float: none;
                        width: 100%;
                        padding-top: 15px;
                    }

                    .result-region {
						padding-top: 0;
						border: 0;
						display: block;
                        font-style: italic;
                        font-size: 14px;
                    }

                    .result-hdl {
						padding: 0 0 .5em;
						border-bottom: 2px solid #ddd;

                        h1,
						.result-hdl__hdl {
							margin: 0.5em 0 0;
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }

                    .result-intro {
                        padding-top: 5px;
                    }

                    .result-read-more {
                        margin-top: 15px;
                        display: inline-block;
                    }
                }
            }

            .result-data {
                position: relative;
                background-color: #ECECEC;
                min-height: 185px;
                width: 100%;
                padding-top: 5px;
                padding-bottom: 10px;

                &:before {
                    content: '';
                    position: absolute;
                    top: -20px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 0;
                    height: 0;
                    border-bottom: solid 20px #ECECEC;
                    border-left: solid 20px transparent;
                    border-right: solid 20px transparent;
                }

                .table {
                    th {
                        width: 33%;
						max-width: 33%;
						border: solid 2px #fff;
						font-size: 1em;
                    }
                    tr {
                        td {
                            border-bottom: none;
                            padding: 5px 10px;

                            &.reihe {
                                font-weight: bold;
                            }
                        }
					}
                }

                .download-btn {
                    margin-top: 5px;
                    margin-left: 10px;
                }

				.result-data__download-bar {
					padding: 5px 0 0;
					margin: 15px 0 0;
					border-top: 2px solid #ddd;
				}
            }
        }

        .spacer {
            display: block;
            width: 100%;
            border-top: 2px solid #ddd;
            margin-top: 15px;
            margin-bottom: 5px;
        }

        .spacer-nmp {
            display: block;
            width: 100%;
            border-top: 2px solid #ddd;
        }
    }
}


section.slider4 {
    .next-prev {
        z-index: 500;
        display: block;
        right: 0;
        position: absolute;
        bottom: -47px;

        .prev, .next {
            display: none;
        }

        .pause {
            a {
                width: 50px;
                height: 50px;
                display: block;
                padding-top: 18px;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding-right: 22px;
                padding-left: 18px;
                padding-bottom: 20px;
            }

            .fa-pause {
            }

            .paused {
                .fa-pause {
                    &:before {
                        content: "\f04b";
                    }
                }
            }
        }
    }
}

/*a.readmore:focus, a:not([role="button"]):focus {
    color: #e94141 !important;
}*/

a.readmore:focus, a:not([role="button"]):focus {
    color: #000 !important;
}

.read-more-arrow-right{
    float:right;
}

.slider4 h1,
.slider4 .slider4__slide-hdl {
    font-size: 2.846em;
    font-weight: 400;
    line-height: 1em;
    margin-bottom: 0.5em;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0.5em;
    margin-right: 10px;
}

.slider4 .slider4__slide-hdl {
	font: 400 2.308em "Signika", sans-serif;
	color: #3f3f3f;
}

.auto-suggestions, .auto-suggestions_mainsearch {
    display: none;
    border: 1px solid #cccccc;
    border-top: 0;
    height: auto !important;
    background-color: white;
    width: 99%;
    width: calc(100% - 2px);
    position: absolute;
    text-align: left;
    z-index: 998; /* above everything but the page header */


    ul {
        margin: 0;

        li {
            margin: 0;
            padding: 0.5em 0 0.5em 2em;
            cursor: pointer;
        }

        .current_suggest {
            color: white;
            background-color: $color-living-hover;
        }
    }
}

#searchfilter {
    li {
        margin: 0;
    }
}

.checkbox-list {
    margin: 0;

    ul {
        margin: 0;
        padding: 0;
    }

    .checkbox {
        color: #585f69;

        input {
            left: -9999px;
            position: absolute;
        }

        input[type=checkbox] {
            margin-left: -20px;
        }
    }

    li {
        position: relative;
        margin: 0;

        &.all-filters {
            margin-left: 5px;
        }
    }

    & > li:first-child {
        .sngl_filter {
            border-top: none;
        }
    }

    .sngl_filter {
        display: table;
        width: 100%;
        border-top: 1px solid #dedede;
        padding: 6px 0;

        label.checkbox {
            //margin-left: 35px;
            position: relative;
            padding-left: 25px;
            padding-right: 25px;
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            margin-right: 5px;
            background: none;
            overflow: visible;

            span {
                display: table-cell;
                max-width: 80%;
                float: left;
                font-size: 13px;
                /*-ms-word-break: break-all;
                word-break: break-all;*/
                @media (min-width: 990px) and (max-width: 1300px) {
                    -ms-word-break: break-all;
                    word-break: break-all;
                }
            }

            i, small {
                position: absolute;
                top: 50%;
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }

            i {
                background: url("../images/input_unchecked.png") 0 0 no-repeat;
                height: 19px;
                width: 17px;
                left: 2px;
            }

            & input:checked + i {
                background: url("../images/input_checked.png") 0 0 no-repeat;
            }

            small {
                right: 0;
            }
        }

        .toggle-sublist {
            // position: absolute;
            padding-left: 5px;
            display: table-cell;
            vertical-align: middle;
            // color: #551285;
            font-size: 16px;
            font-weight: bold;
            min-width: 25px;
            margin-right: 5px;

            &.fa {
                cursor: pointer;
            }
        }


        .checkbox i {
            position: relative;
            float: left;
        }

        .checkbox small {
            padding-right: 10px;
        }
    }

    .level-2-check {
        border-left: 7px solid #9c9c9c;

        .sngl_filter {
            //  background-color: #f6f6f6;
        }
    }

    .level-3-check {
        border-left: 7px solid #9c9c9c;

        .sngl_filter {
            //  background-color: #eaeaea;
        }
    }

    .list-closed {
        display: none;
    }
}

.search-results {
    p {
        clear: none;
    }

    h2.stripes {
        margin-top: 0;
    }
}

.main-search,
.main-search-mobile {
    .input-group {
        position: relative;
    }

    input {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
    }

    .main-search-submit {
        background: none;
        border: none;
        border-radius: 0;
        text-shadow: none;
        box-shadow: none;
        background-color: #9B1800;
        height: 37px;
        margin-left: -4px;
        position: absolute;
        top: 0;
        right: 0;
    }
}

.main-search-mobile {
    margin-top: 15px;
}

@media (max-width: 800px) {
    .main-search-mobile {
        display: block;
    }

    .main-search {
        display: none;
    }
}

@media (min-width: 801px) {
    .main-search-mobile {
        display: none;
    }

    .main-search {
        display: block;
    }
}

.hits {
    border-bottom: 0;
}

// RS-Button Styles
.rsbtn {
    float: right;
    margin-top: 30px !important;
    z-index: 1;

    .js_showHideRS {
        cursor: pointer;
        margin-right: 5px;
        padding-top: 7px;
        float: right;
    }

    .rsbtn_play {
        display: none !important;
        margin-right: 15px !important;
    }

    &.rsexpanded {
        .rsbtn_play {
            margin-right: 0 !important;
        }

        .rsbtn_exp {
            margin-right: 15px;
        }
    }
}

.schoolSearch {
    margin-top: 1em;
    padding: 5px;
    background-color: #bebebe;

    .half {
        width: 50%;
        float: left;



        input {
            width: 90%;
            padding: 8px 3%;
        }

        .select, select {
            width: 100%;
        }

        @media (max-width: 800px) {
            & {
                width: 100%;
                float: left;

                input {
                    width: 92%;
                }

                .select, select {
                    width: 98%;
                }
            }
        }
    }

    .searchCommit {
        text-align: right;
        margin-top: 1em;
        width: 100%;
        padding-bottom: 1em;
    }
}

#schoolMapWrapper {
    height: 250px;
    position: relative;

    #map {
        height: 100%;
        width: 100%;
        border-bottom: 6px solid #bebebe;
    }

    #scrollLeftSpacer, #scrollRightSpacer {
        height: 100%;
        width: 75px;
        position: absolute;
        top: 0;
        z-index: 500;
    }

    #scrollLeftSpacer {
        left: 0;
    }

    #scrollRightSpacer {
        right: 0;
    }

    #mapToggle {
        position: absolute;
        bottom: 0;
        height: 25px;
        text-align: center;
        width: 100%;

        span {
            position: relative;
            width: 45px;
            height: 25px;
            background: #bebebe;
            display: inline-block;
            cursor: pointer;
            padding-top: 3px;

            i {
                font-size: 1.5em;
            }
        }
    }
}

.schoolTeaser {
    // font-size: 0;
    padding-top: 10px;
}

.schoolInfo {
    //  border-top: 3px solid red;
    padding: 10px 0;

    &:after {
        visibility: hidden;
        display: block;
        content: "";
        clear: both;
        height: 0;
    }

    &.living {
        border-bottom: 3px solid $mtk-red;
    }

    &.area {
        border-bottom: 3px solid $mtk-blue;
    }

    &.tourism {
        border-bottom: 3px solid $apple-green;
    }

    &.economy {
        border-bottom: 3px solid $mtk-gray;
    }


    h3 {
        border-bottom: none !important;
        margin: 0 !important;


        a {
            //font-size: 18.0161px !important;
        }
    }

    .readmore {
        float: right;
    }

    p, a {
        //  font-size: 13.008px;
    }
}

.content .aside-bt, .main p, .main ul {
    a {
        color: #aa2108;
        font-weight: bold;

        &:hover {
            color: #aa2108;
            text-decoration: underline;
        }
    }
}

.jobList {
    .ListEntry:after {
        visibility: hidden;
        display: block;
        content: "";
        clear: both;
        height: 0;
    }

    .ListEntry {
        margin: 15px 0;
    }

    .entryLeft {
        float: left;
        width: 15%;
        display: inline-block;

        @media (max-width: 480px) {
            width: 100%;
        }
    }


    .entryRight {
        float: left;
        width: 80%;
        display: inline-block;

        @media (max-width: 480px) {
            width: 100%;
        }

        a {
            color: #aa2108;
            font-weight: bold;

            &:hover {
                color: #aa2108 !important;
                text-decoration: underline;
            }
        }

        p {
            margin: 5px 0 0;
        }
    }
}




//## School-DB START

@import "mainimport";

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}



//## School-DB Search-Formular



//## Schoolform and Locations
#schoolnav, #eventsearch {
    background: none !important;

    .location {
        @include clearfix;
        background: #f5f5f5;
        padding: 2%;
    }

    .category-wrap {
        background: #f5f5f5;
        padding: 2%;
        margin-top: 25px;
        position: relative;
        overflow: hidden;
    }
}

#eventsearch {
    .category-wrap .inputblock:first-child input {
        border-right: 1px solid #ccc;
    }

    .tab-content .location {
        width: 96% !important;
    }

    .location .place input {
        width: 74% !important;
        border-right: none;
    }

    div.inputblock {
        width: 48%;
        float: left;
    }

    div.inputblock:first-child {
        margin-right: 2%;
    }

    div.all {
        height: auto;
        clear: both;
    }

    .location {
        @include clearfix;

        label {
            margin-top: 0;
        }

        .place {
            width: 100%;
            float: left;
            overflow: hidden;

            input {
                width: 94%;
            }
        }



        .radius {
            width: 18%;
            float: left;
            margin-right: 0;

            .value {
                padding: 9px 0 9px 0;
                text-align: center;
            }

            select {
                width: 100%;
                padding: 8px 0 8px 0;
            }

            .select {
                width: 98%;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                height: 35px;
            }


            .focus {
                @include whitegradientfocus;
            }
        }
    }

    .types {
        width: 100%;
        @include clearfix;
        margin-top: 16px;

        .value {
            padding: 9px 0 9px 0;
            text-align: center;
        }

        select {
            width: 100%;
            float: left;
        }

        .select {
            float: left;
            width: 49%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            height: 35px;

            &:first-child {
                margin-right: 1.1%;
            }
        }
    }

    .alternative {

        .place {
            width: 100%;
            float: left;
            overflow: hidden;

            .select {
                width: 98%;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                background: white;
                background: -moz-linear-gradient(top, white 0%, #f0f0f0 100%);
                background: -webkit-linear-gradient(top, white 0%, #f0f0f0 100%);
                background: linear-gradient(to bottom, white 0%, #f0f0f0 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f0f0f0',GradientType=0 );
                height: 35px;
            }

            select {
                width: 100%;
                padding: 8px 0 8px 0;
            }

            input {
                width: 94%;
            }

            .value {
                padding: 9px 0 9px 0;
                text-align: center;
            }
        }

        .radius {
        }
    }
}

#schoolnav {
    @mixin dropdowngradient {
        background: rgb(255,255,255);
        background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 100%);
        background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(240,240,240,1) 100%);
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(240,240,240,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f0f0f0',GradientType=0 );
    }

    margin-bottom: 35px;
    @include clearfix;
    background: none;
    padding: 0%;

    .location {
        @include clearfix;

        label {
            margin-top: 0;
        }

        .place {
            width: 80%;
            float: left;
            overflow: hidden;

            input {
                width: 94%;
            }
        }



        .radius {
            width: 20%;
            float: left;

            .value {
                padding: 9px 0 9px 0;
                text-align: center;
            }

            select {
                width: 100%;
                padding: 8px 0 8px 0;
            }

            .select {
                width: 98%;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                @include dropdowngradient;
                height: 35px;
            }


            .focus {
                @include whitegradientfocus;
            }
        }
    }

    .alternative {

        .place {
            width: 100%;
            float: left;
            overflow: hidden;

            .select {
                width: 98%;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                background: white;
                background: -moz-linear-gradient(top, white 0%, #f0f0f0 100%);
                background: -webkit-linear-gradient(top, white 0%, #f0f0f0 100%);
                background: linear-gradient(to bottom, white 0%, #f0f0f0 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f0f0f0',GradientType=0 );
                height: 35px;
            }

            select {
                width: 100%;
                padding: 8px 0 8px 0;
            }

            input {
                width: 94%;
            }

            .value {
                padding: 9px 0 9px 0;
                text-align: center;
            }
        }

        .radius {
        }
    }

    .types {
        width: 100%;
        @include clearfix;
        margin-top: 16px;

        .value {
            padding: 9px 0 9px 0;
            text-align: center;
        }

        select {
            width: 100%;
            float: left;
        }

        .select {
            float: left;
            width: 49%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            @include dropdowngradient;
            height: 35px;

            &:first-child {
                margin-right: 1.1%;
            }
        }
    }

    .ui-widget {
        font-size: 100%;
        font-family: "Arial", sans-serif;
    }

    fieldset {



        label.checkbox {
            background: url("../images/input.png") 0 1px no-repeat;
        }

        label.checkbox.checked {
            background-position: 0 -18px;
        }

        label {
            display: block;
            width: 100%;
        }

        .label {
            display: block;
            width: 46%;
            float: left;
            border-bottom: solid 1px #d9d9d9;
            padding-bottom: 4px;
            margin-bottom: 4px;



            &:nth-child(odd) {
                margin-right: 7%;
            }
        }

        legend {
			padding: 1.5em 0 0.5em;
			display: block;
			font: 600 1.2em "Signika",sans-serif;
            color: #555555;
        }

        .ui-widget-content {
            border: 0;
            background: none;
        }

        .ui-accordion-content {
            padding: 0;
            overflow: hidden;
        }

        .ui-state-default {
            border: 0;
            background: none;
            padding: 0 5px 0 0;
            margin: 12px 0 12px 0;
            display: inline-block;
        }

        .ui-accordion-header {
            font-size: 1.2em;

            h3 {
                &:focus {
                    text-shadow: 0.1em 0.1em 0.05em #333;
                }
            }

            &::after {
                content: "\f0fe";
                font-family: FontAwesome;
                font-weight: 200;
                margin-left: 5px;
                color: lighten(#000, 50%);
            }

            &:hover:after {
                cursor: pointer;
                color: lighten(#000, 20%);
            }

            span {
                border-bottom: 0;
            }

            &:focus {
                outline: 0;
                color: #000;
            }
        }

        .ui-accordion-header-active {
            &::after {
                content: "\f146" !important;
                font-family: FontAwesome;
                font-weight: 200;
                margin-left: 5px;
                color: lighten(#000, 50%);
            }

            &:hover:after {
                cursor: pointer;
            }

            &:focus {
                outline: 0;
                color: #000;
            }
        }
    }

    .submit {
        width: 33%;
        display: block;

        input {
            width: 93%;
            background: #06799f;
            color: white;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            border-radius: 4px;
            margin: 20px 0 5px 0;
            border: transparent;
        }
    }


    .schoolform {

        form {
            float: left;
            margin: 0;
            width: 55%;

            span.select {
                width: 48%;
            }

            select {
                width: 100%;
            }
        }
    }
    //## Seach Field
    .search {

        input {
            padding: 9px 1% 8px 1%;
            width: 31%;
            margin: 0 1px 0 0;
        }

        input[type=submit] {
            padding-top: 9px;
            padding-bottom: 8px;
            width: 10%;
            margin: 0 1px 0 0;
        }
    }
}

//## Google Maps incl. Toggle function
.maps {
    position: relative;
    //## Displays the map
    #googleMap {
        height: 400px;
        width: 100%;
		display: block;
        text-align: center;
        margin: 0;
    }
    //## Button that changes the size of the Google Map
    #mapToggle {
		padding: .5em 0;
		margin-bottom: 2em;
        text-align: center;
        color: $colorwhite;
        background: darken(#f5f5f5, 5%);

        &::before {
            font-family: FontAwesome;
            content: '\f077';
            font-size: 2.5em;
            padding: 4px 25px 1px 25px;
            color: lighten(rgba(128,128,128, 1), 1%);
            margin: 0 auto;
        }

        &:hover {
            cursor: pointer;
        }

        &:hover:before {
            cursor: pointer;
            color: darken(rgba(128,128,128, 1), 20%);
        }
    }

    .minimized#mapToggle {
        &::before {
            content: '\f078';
        }

        &:hover:before {
            color: darken(rgba(128,128,128, 1), 10%);
        }

        &::before {
            border-width: 0;
        }
    }
}
// Copyright Images
.results {
    position: relative;


    .copyright, .title {
        position: relative;
        //bottom: -3px;
        font-size: 11px;
        width: 135px;
        clear: both;
        float: left;
        line-height: 13px;
    }
}

.eventlist.results {
    .copyright, .title {
        bottom: 0;
    }
}

.slide {
    .copyrightbox {
        position: absolute;
        bottom: 0;
        background: rgba(255,255,255,0.7);
        padding: 3px 10px;
    }
}

.teaserimgleft {
    .copyright, .title {
        clear: both;
        float: left;
    }
}

.teaserimgright {
    .copyright, .title {
        clear: both;
        float: right;
    }
}

@media (min-width: 760px) {
    .teaserimgright {
        .copyright, .title {
            width: 272px;
        }
    }
}

#accordion .ui-accordion-content .teaserimgtop .img-border {
    margin-bottom: 5px !important;
}

.teaserimgtop {
    .copyright, .title {
        clear: both;
        margin-right: 7px;
    }
}

//## Whole content area
.content {
    //## Schools Wrapper
    .schools {
		h2,
		.schools__result-hdl {
			margin: 0;
			color: #3f3f3f;
			font: 400 1.538em "Signika",sans-serif;
		}

        //## Global CSS for every single school
        .school {
            margin-bottom: 10px;
            padding: 20px 20px 30px 20px;
            border-bottom: solid 1px #d0d0d0;

			&:last-of-type {
				border-bottom: none;
			}

            p {
                margin: 2px;
                font-size: 1.13em;
            }

            p.distance {
                margin-top: 6px;
                font-size: .9em;

                span {
                    font-style: italic;
                    margin-left: 5px;
                }
            }
            //## Left floated content inside schools wrapper
            .left {
                float: left;
                width: 70%;

                .img-border {
                    img {
                        width: 100%;
						display: block;
                    }
                }

				@media (max-width: 660px) {
					width: 100%;
				}
            }
            //## Right floated content incl. location change button
            .right {
                float: right;
                //## display on map button
                span {
                    display: block;
                    background: $bluecolor;
                    color: $colorwhite;
                    text-decoration: none;
                    @include border-radius($radius);

                    a {
                        color: $colorwhite;
                        display: block;
                        padding: 6px;
                    }

                    &:hover {
                        cursor: pointer;
                        background: darken($bluecolor, 5%);
                    }
                }

                p {
                    margin: 45px 0 20px 0;
                    text-align: right;
                }
                //## button for details of school
                .details a {
                    color: $colorwhite;
                    text-align: center;
                    display: block;
                    //background: rgba(238, 54, 36, 0.8);
                    background: lighten($bluecolor, 7%);
                    padding: 5px;
                    margin-top: 10px;
                    @include border-radius($radius);

                    &:hover {
                        background: lighten($bluecolor, 5%);
                    }
                }
            }

			figure {
				width: 30%;
				margin-right: 2%;
				float: left;
			}

			figure + .schools__result-hdl,
			figure ~ p {
				width: 68%;
				float: right;
			}

			figure ~ p {
				margin-left: 0;
				margin-right: 0;
			}

			@media (max-width: 500px) {
				figure {
					width: 100%;
					margin-right: 0;
					float: none;

					figcaption {
						width: 100%;
						float: none;
					}
				}

				figure + .schools__result-hdl {
					margin-top: .5em;
				}

				figure + .schools__result-hdl,
				figure ~ p {
					width: 100%;
					float: none;
				}

				figure ~ p {
					margin-left: 0;
					margin-right: 0;
				}
			}
        }
    }
    //## Global clear class
    .clear {
        clear: both;
    }

    @media (max-width: 660px) {
        #schoolnav {
            @mixin dropdowngradient {
                background: rgb(255,255,255);
                background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 100%);
                background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(240,240,240,1) 100%);
                background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(240,240,240,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f0f0f0',GradientType=0 );
            }

            margin-bottom: 35px;
            @include clearfix;
            background: #f5f5f5;
            padding: 2%;

            .location {
                width: 100%;
                @include clearfix;

                label {
                    margin-top: 0;
                }

                .place {
                    width: 70%;
                    float: left;
                    overflow: hidden;

                    input {
                        width: 94%;
                    }
                }

                .radius {
                    width: 30%;
                    float: left;

                    .value {
                        padding: 9px 0 9px 0;
                        text-align: center;
                    }

                    select {
                        width: 100%;
                    }

                    .select {
                        width: 98%;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        @include dropdowngradient;
                        height: 35px;
                    }
                }
            }

            .types {
                width: 100%;
                @include clearfix;
                margin-top: 16px;

                .value {
                    padding: 9px 0 9px 0;
                    text-align: center;
                }

                select {
                    width: 100%;
                    float: left;
                }

                .select {
                    float: left;
                    width: 49%;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    @include dropdowngradient;
                    height: 35px;

                    &:first-child {
                        margin-right: 1.1%;
                    }
                }
            }

            .ui-widget {
                font-size: 100%;
                font-family: "Arial", sans-serif;
            }

            fieldset {
                border: solid 1px #d9d9d9;
                margin-top: 12px;
                border-radius: 4px;

                label.checkbox {
                    background: url("../images/input.png") 0 1px no-repeat;
                }

                label.checkbox.checked {
                    background-position: 0 -18px;
                }

                label {
                    display: block;
                    width: 100%;
                    margin: 2%;
                }

                .label {
                    display: block;
                    width: 100%;
                    float: left;
                    padding: 0;
                    margin-bottom: 0;
                    background: #fff;

                    &:nth-child(odd) {
                        margin-right: 7%;
                    }
                }

                h3 {
                    font-size: 1.1em;
                    margin: 0 0 0 0;
                    padding: 2%;
                    background: #f8f8f8;
                    border-color: #dedede;
                    display: block;
                    border-bottom: solid 1px #d9d9d9;
                }

                .ui-widget-content {
                    border: 0;
                    background: none;
                }

                fi .ui-accordion-content {
                    padding: 0;
                }

                .ui-state-default {
                    border: 0;
                    background: none;
                    padding: 0 5px 0 0;
                    margin: 12px 0 12px 0;
                    display: inline-block;
                }

                .ui-accordion-header {
                    font-size: 1.1em;
                    cursor: pointer;
                    position: relative;
                    margin: 0 0 0 0;
                    min-height: 20px;
                    padding: 0 0 0 2%;
                    background: #f8f8f8;
                    border-color: #dedede;
                    display: block;
                    border-bottom: solid 1px #d9d9d9;

                    &::after {
                        content: "\f0fe";
                        font-family: FontAwesome;
                        font-weight: 200;
                        margin-left: 5px;
                        color: lighten(#000, 50%);
                    }

                    &:hover:after {
                        cursor: pointer;
                        color: lighten(#000, 20%);
                    }

                    span {
                        border-bottom: 0;
                    }

                    &:focus {
                        outline: 0;
                    }
                }

                .ui-accordion-header-active {
                    &::after {
                        content: "\f146" !important;
                        font-family: FontAwesome;
                        font-weight: 200;
                        margin-left: 5px;
                        color: lighten(#000, 50%);
                    }

                    &:hover:after {
                        cursor: pointer;
                    }
                }
            }
        }
        //## Right floated content incl. location change button
        .left {
            /*width: 100% !important;*/
            margin-bottom: 20px;
        }

        .right {
            float: none !important;
            clear: both;
            margin: 20px auto;
            width: 75%;

            span {
                text-align: center;
                margin-top: 25px;

                a:focus {
                    color: #fff !important;
                }
            }
        }
    }
    //## end @media max-width:660px
    @media (max-width: 480px) {
        //## Paging-Nav - Navigation for Paging
        .schoolform form {

            span.value {
                width: 92% !important;
                margin: 0;
            }
        }

        .right {

            a {
                padding-top: 15px !important;
                padding-bottom: 15px !important;
            }
        }
    }
}
//## end @media max-width:480px

//## School-DB END

//## School-DB-Details START



@mixin marpadzero($margin: 0, $padding: 0) {
    margin: $margin;
    padding: $padding;
}

.schoolvariant {
    font-size: 0.9em;
    font-weight: 600;
    margin: 0;
}


.typesearch {
    label.checkbox {
        input {
            width: auto !important;
        }
    }
}

.schooldb {
    .schooldetailshead {

        @include clearfix;
        background: #f5f5f5;
        padding: 3%;

        .maptext {
            @include clearfix;
        }

        .schoolinfos {
            float: left;
            width: 48%;
            padding: 0 0 0 0;
            margin-right: 2%;
            min-height: 230px;

            > * {
                margin: 0;
            }

            .block {
                margin-top: 16px;

                p {
                    margin-bottom: 12px;
                    margin-top: 2px;
                }
            }
        }

		.schoolinfos__shortinfo {
			dt {

				&.schoolinfos-hdl {
					font-family: "Signika", sans-serif;
					color: #3f3f3f;
				}
			}

			dd + dt {
				margin-top: .8em;
			}

			dd {
				p {
					margin: 0 0 .4em;
				}
			}
		}

        .maps {
            width: 50%;
            float: right;
            height: 230px;

            #googleMap {
                height: 230px;

                .confirmation {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    overflow-x: hidden;
                    overflow-y: auto;

                    > :first-child {
                        margin-top: auto;
                    }

                    > :last-child {
                        margin-bottom: auto;
                    }

                    > p {
                        margin-top: 0;
                    }

                    .button {
                        align-self: center;
                        padding-left: 0.5em;
                        padding-right: 0.5em;
                        margin-top: 0;
                    }
                }
            }
        }

        .moreinfo {
			padding: 1em 0 0;
            border-top: solid 1px lighten(#000, 80%);
            margin-top: 25px;

            h3 {
                margin-bottom: 6px;
                line-height: 1.2em;
                font-size: 1.3em;
                font-weight: 600;
            }

            p {
                margin-top: 2px;
                margin-bottom: 5px;
            }
        }

        .fontreset, .download {
            font: 1em/1.5em "Arial", sans-serif;
            font-weight: 600;
            word-break: break-all;
        }

		.schoolinfos-hdl {
			margin-top: 0px;
			margin-bottom: 2px;
			line-height: 1.3em;
			font-size: 1.3em;
			font-weight: 600;
		}
    }

    .schoolsdetailalternative {

        .schoolinfos {
            min-height: 0 !important;
            width: 100%;

            .block {
                border-bottom: solid 1px darken(#eee, 8%);
                margin-bottom: 12px;
                padding-bottom: 12px;
                margin-top: 0;

                h3 {
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: 1.3em;
                    font-weight: 600;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                p {
                    margin-bottom: 0;
                    margin-top: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.childsittingdb {
    .table {
        .table-row {
            .table-cell:last-child {
                min-width: 320px !important;
            }
        }
    }
}

.ch-alternative.schooldetailshead {

    p {
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }

    h3 {
        margin: 0 !important;
    }

    .maptext {
        width: 48%;
        float: left;

        .block {
            margin: 0;
        }

        .schoolinfos {
            width: 100%;
        }
    }

    .moreinfo {
        border-top: 0;
        float: right;
        width: 50%;
        margin: 0;
    }

    @media (max-width: 660px) {


        .maptext {
            width: 100%;
            float: none;

            .block {
                margin: 0;
            }

            .schoolinfos {
                width: 100%;
                min-height: 0;
            }
        }

        .moreinfo {
            border-top: 0;
            float: none;
            width: 100%;
            margin: 0;
        }
    }
}

.facilitydetail {

    .ui-accordion .ui-accordion-content {
        padding: 0;
        border: 0;
        overflow: hidden;
    }

    .ui-state-default {
        border: 0;
    }

    .ui-widget {
        font-size: 1em !important;
        font-family: "Arial", sans-serif;
    }

    .ui-helper-reset {
        line-height: 1.5;
    }

    .contactpersonwrap {
        .contactpersonhead {
            margin-top: 8px !important;
        }
    }

    .ui-accordion .ui-accordion-header {
        display: block;
        cursor: pointer;
        position: relative;
        margin: 0;
        padding: 10px 10px;
        min-height: 0;
        font-size: 100%;
        border-radius: 0 !important;
    }

    .accordioncontent {
        width: 100%;
    }

    .contactperson {
        overflow: hidden;

        .table-row {

            .table-cell {
            }
        }
    }

    .textblock {
        background: whitesmoke;
        padding: 2%;
        margin-top: 2.5em;

        h3 {
            font-size: 1.2em;
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 5px;

            &:first-child {
                margin-top: 0.5em;
            }
        }

        p {
            margin-top: 3px;
        }
    }

    .opentimes {
        margin-top: 0;

        p {
            line-height: 1.8em;
            margin: 0;
        }
    }

    .maps {
        //margin-top: 25px;
    }

    .table .table-row:last-child .table-cell {
        border-bottom: 1px solid #dedede;
    }

    .contactpersonhead {
        background: #dedede !important;
        color: #000 !important;
    }

	table, 
	.table, 
	.moreinfo {
        a {
            color: #aa2108;
            font-weight: bold;
        }
    }

    &.living {
        .responsive-table thead th {
            background-color: $mtk-red;
            border: 1px solid $mtk-red;
            color: white;
        }

        .table .table-row:last-child .table-cell {
            border-bottom: 1px solid $mtk-red;
        }

        .contactpersonhead {
            background: $mtk-red !important;
            color: #fff !important;
        }

        @media (min-width: 30em) {
			table,
			.responsive-table {
				tbody {
					tr:last-of-type {
						th,
						td {
							border-bottom: 1px solid $mtk-red;
						}
					}
				}
			}
        }
    }

    &.area {
        .responsive-table thead th {
            background-color: $mtk-blue;
            border: 1px solid $mtk-blue;
            color: white;
        }

        .table .table-row:last-child .table-cell {
            border-bottom: 1px solid $color-area;
        }

        .contactpersonhead {
            background: $color-area !important;
            color: #fff !important;
        }

        @media (min-width: 30em) {
			table,
			.responsive-table {
				tbody {
					tr:last-of-type {
						th,
						td {
							border-bottom: 1px solid $color-area;
						}
					}
				}
			}
        }
    }

    &.tourism {
        .responsive-table thead th {
            background-color: $apple-green;
            border: 1px solid $apple-green;
            color: white;
        }

        .table .table-row:last-child .table-cell {
            border-bottom: 1px solid $apple-green;
        }

        .contactpersonhead {
            background: $apple-green !important;
            color: #fff !important;
        }

        @media (min-width: 30em) {
			table,
			.responsive-table {
				tbody {
					tr:last-of-type {
						th,
						td {
							border-bottom: 1px solid $apple-green;
						}
					}
				}
			}
        }
    }

    &.economy {
        .responsive-table thead th {
            background-color: $mtk-gray;
            border: 1px solid $mtk-gray;
            color: white;
        }

        .table .table-row:last-child .table-cell {
            border-bottom: 1px solid $mtk-gray;
        }

        .contactpersonhead {
            background: $mtk-gray !important;
            color: #fff !important;
        }

        @media (min-width: 30em) {
			table,
			.responsive-table {
				tbody {
					tr:last-of-type {
						th,
						td {
							border-bottom: 1px solid $mtk-gray;
						}
					}
				}
			}
        }
    }

	.facilitydetail__section-hdl {
		margin: 0 0 0.5em;
		color: #3f3f3f;
		font: 300 1.6em "Signika", sans-serif;
	}

	> section:not(.detailinfo) {
		padding: 1.5em 0 0;
	}

	table,
	.responsive-table {
		tr {
			th,
			td {
				border: 2px solid #fff;
			}

			th,
			td {
				word-wrap: break-word; /* microsofts version */
				overflow-wrap: break-word; /* official CSS spec version */

				&.url {
					word-wrap: normal;
					overflow-wrap: normal;
					word-break: break-all;
				}
			}
		}
	}

	table {
		tr:last-of-type {
			border-bottom: 0;
		}
	}

	.responsive-table {
		thead {
			th {
				background-color: #dedede;
				border: 1px solid #dedede;
				color: #646464;
			}
		}
	}
}

@media (max-width: 660px) {

    .schooldb {

        .schooldetailshead {
            .schoolinfos {
                width: 100%;
                min-height: 0;
            }

            .maps {
                width: 100%;
            }
        }
    }
}

tr {
	background: whitesmoke;
	border: solid 2px #fff; 
}

td {
	border: solid 2px #fff; 
}

.dataTable table caption {
	font-size: 14px;
	padding: 8px;
	text-align: left;
	color: #3f3f3f !important;
	background-color: #ececec !important;
	border: 1px solid  #3f3f3f !important; 
}

.dataTable table, .dataTable table td, .dataTable table th {
	color: #3f3f3f;
	border: 1px solid  #3f3f3f !important;
	border-collapse: collapse;
	font-family: verdana;
	font-size: 12px; 
}

.dataTable table th, .dataTable table td {
	padding: 4px;
	text-align: right; 
}

.dataTable table th {
	font-weight: bold;
	background-color: #f5f5f5 !important;
	text-align: left; 
}

.dataTable table tr:nth-child(even) {
	background: #faf0e6 !important;
	border: 0px !important; 
}

.dataTable table tr:nth-child(odd) {
	background: #ffffff !important;
	border: 0px !important; 
}

#accordion {
    color: #4e4e4e;
    font-size: 1em;
    font-family: "Arial", sans-serif;

    h3 {
        border: $onepxsolid transparent !important;
    }

    .ui-accordion-content {
        padding: 0;
        border: $onepxsolid transparent !important;
        @include clearfix;
        line-height: 1.5;

        .teaserimgleft {

            .img-border {
                width: 44%;
                float: left;
                margin-right: 1.5%;

                img {
                    width: 100%;
                }
            }
        }

        .teaserimgright {

            .img-border {
                width: 40%;
                float: right;
                margin-left: 2%;

                img {
                    width: 100%;
                }
            }
        }

        .teaserimgtop {

            .img-border {
                width: 100%;
                margin-bottom: 20px;

                img {
                    width: 100%;
                }
            }
        }
    }

    p {
        margin-top: 0;
    }


    ul {
        overflow: hidden;
        margin: 0;

        li {
            padding-left: 5px;
            margin-left: 35px;
        }
    }

    .accordion-toggle {
        cursor: pointer;
        background: lighten($greycolor, 48%);
        @include border-radius($radius);
        padding: 5px 30px 5px 40px;
        margin-top: 15px;
        margin-bottom: 15px;
        color: darken($colorgrey, 30%);
        font-size: 1.65em;
        position: relative;
        font-weight: 300;

		&:first-child {
			margin-top: 0;
		}

        &:focus {
            outline: none;
            background: lighten($greycolor, 40%);
        }

        &:before {
            content: '\f078';
            @include toggle-arrow;
        }

        &:hover:before {
            @include accordion-arrowhover;
        }
    }

    .ui-accordion-header-active {

        &:before {
            content: '\f077';
            @include toggle-arrow;
        }

        &:hover:before {
            @include accordion-arrowhover;
        }
    }
}

#slider {
    position: relative;
    border-radius: $radius;
    overflow: hidden;
    margin-bottom: 40px;
    max-height: 400px;
    display: block;

    img {
        cursor: pointer;
    }

    ul {
        list-style: none;
        @include marpadzero;

        li {
            float: left;
            width: 100%;
            @include marpadzero;
            position: absolute;

            img {
                width: 100%;
            }
        }

        li.firstimg {
            display: block;
        }
    }

    div.prev, div.next {
        width: 9%;
        height: 100%;
        display: block;
        position: absolute;
        opacity: 0;
        cursor: pointer;
        @include slidertransition;
        font-size: 8em;
        color: #FFF;
        text-align: center;
        @include sliderbuttons;
        @include textshadow;

        span {
            position: relative;
            top: 46%;
            display: block;
        }
    }

    div.prev:hover, div.next:hover {
        display: block !important;
        opacity: 1;
        @include slidertransition;
    }

    &:hover .prev, &:hover .next {
        opacity: 0.6;
        @include slidertransition;
    }

    div.next {
        right: 0;
    }
}

.responsive-table {
    @media (min-width: 48em) {
        font-size: .9em;
    }

    @media (min-width: 62em) {
        font-size: 1em;
    }
}

.responsive-table thead {
    position: absolute;
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;

    th {
        background-color: #1d96b2; //## blue background
        border: $onepxsolid #1d96b2;
        font-weight: normal;
        text-align: center;
        color: white;
    }

    @media (min-width: 48em) {
        position: relative;
        clip: auto;
        height: auto;
        width: auto;
        overflow: auto;
    }
}

.contacttable td:first-child {
    display: none;
}

.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
}

.responsive-table .minus {
    font-size: 1.6em;
    color: #aaa;
    padding-bottom: 5px;

    @media (max-width: 48em) {
        display: inline;
        font-size: initial;
        color: initial;
        padding-bottom: 0;
    }

    @media (min-width: 62em) {
        display: block;
    }
}

.responsive-table td .textwrap {
    margin-top: 8px;
    padding-top: 8px;

    &:first-child {
        margin-top: 0;
    }

    @media (max-width: 48em) {
        border-top: 0 !important;
        margin-top: 8px;
        padding-top: 8px;
    }

    @media (min-width: 62em) {
        display: block;
    }
}

@media (min-width: 48em) {
    .responsive-table tr {
        display: table-row;
    }
}

.responsive-table th,
.responsive-table td {
    padding: .5em;
    vertical-align: middle;

    @media (min-width: 30em) {
        padding: .75em .5em;
    }

    @media (min-width: 48em) {
        display: table-cell;
        padding: .5em;
    }

    @media (min-width: 62em) {
        padding: .75em .5em;
    }

    @media (min-width: 75em) {
        padding: .75em;
    }

	&.txt-left {
		text-align: left;
	}

	&.txt-center {
		text-align: center;
	}

	&.txt-right {
		text-align: right;
	}
}

@media (min-width: 48em) {
    .responsive-table tbody {
        display: table-row-group;
    }
}

.responsive-table tbody tr {
    margin-bottom: 1em;
}

.contacttable tbody tr {
    margin-bottom: 0;
}

@media (min-width: 48em) {
    .responsive-table tbody tr {
        display: table-row;
        border-width: 1px;
    }
}

.responsive-table tbody tr:last-of-type {
    margin-bottom: 0;
}


.responsive-table tbody td {
    text-align: right;

    @media (min-width: 30em) {
        border-bottom: $onepxsolid #1d96b2; //## blue border bottom
    }

    @media (min-width: 48em) {
        text-align: center;
    }
}

.responsive-table tbody td[data-title]:before {
    content: attr(data-title);
    float: left;
    color: rgb(94, 93, 82);
    font-weight: 600;

    @media (min-width: 48em) {
        content: none;
    }
}

.back {
    margin-top: 18px;
    font-family: FontAwesome;

    .linkback {
        font-size: 1em;
        font-family: "Arial", sans-serif;

        &:hover {
            text-decoration: underline;
        }
    }
}

#slider {
    @media (max-width: 660px) {


        div.next, div.prev {
            font-size: 6em;
        }
    }

    @media (max-width: 480px) {

        div.next, div.prev {
            font-size: 4em;
            width: 15%;
        }
    }
}


@media (max-width: 480px) {
    .ui-accordion-content {


        @include clearfix;

        .teaserimgleft {

            .img-border {
                width: 100% !important;
                float: left;
                margin-right: 0 !important;
                margin-bottom: 10px;

                img {
                    width: 100%;
                }
            }
        }
    }
}

.contactpersonhead {
    display: block;
    color: #fff !important;
    padding: 10px 10px;
    background: #1d96b2 !important;
    border: solid 1px #fff;
}

.table {
    display: table;
    width: 100%;

    .table-row {
        display: table-row;

        &:last-child .table-cell {
            border-bottom: $onepxsolid #1d96b2;
        }

        .table-cell {
            display: table-cell;
            padding: 10px 10px;
            @include whitesmoke;
            border: solid 1px #fff;
        }

        .thead {
            font-weight: bold;
            @include whitesmoke;
        }
    }
}

.table {
    @media only screen and (max-width: 800px) {
        border: none;
        table-layout: fixed;

        .table-row {
            display: block;
            margin-bottom: 10px;

            div.table-cell {
                @include tablecell;
            }

            &:last-child .table-cell {
                border-bottom: solid 0px;
            }
        }
    }
}

div.table-cell {
    @media (max-width: 480px) {

        @include tablecell;
    }
}
//## School-DB-Details END


//## Event-Calendar START

#googleMap > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(2) {
    display: none;
    height: 0px;
}

#googleMap > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(4) {
    display: none;
    height: 0px;
}

#googleMap > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div {
    height: 0px !important;
    width: 250px !important;
}

#googleMap > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(3) {
    top: 19px !important;
}

#googleMap > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div.gm-style-iw > div {
    min-height: 100px;
    max-height: none !important;
    max-width: 300px !important;
    width: 100%;
    display: block !important;






}

#googleMap {
    background-color: lighten($colorgrey, 30%);
    display: table;
    margin: auto;
    text-align: center;

    .confirmation {
		padding-top: 15%;

        span {
            display: block;
        }

        .button {
            background: rgba($colorwhite, 0.6);
            width: 30%;
            display: block;
            margin-top: 20px;
            padding-top: 6px;
            padding-bottom: 6px;
            text-decoration: none !important;
            border: solid 1px lighten($greycolor, 35%);

            &:hover {
                background: rgba($colorwhite, 0.7);
            }
        }

        .yes {
            display: inline-block;
        }

        .button {
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}

.gm-style-iw {
  /*  width: 240px !important;
    top: 15px !important; // move the infowindow 15px down
    left: 0 !important;
    background-color: #fff;
    box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
    border: 1px solid rgba(72, 181, 233, 0.6);
    border-radius: 2px 2px 0 0;
    max-height: 280px;
      */
    
/*  width: 215px !important;
  top: 15px !important; // move the infowindow 15px down
  left: 1px !important;
  background-color: #fff;
  height: 140px  !important; */




    top: 8px !important;
    background-color: #fff;
    border-radius: 2px 2px 0 0;
    max-height: 280px;




}

.gmapsinfo {
    width: 100%;

    a {
        .link {
            font-weight: 500;
            background-color: lighten($greycolor, 56%);
            display: block;
            padding: 5px 10px;
            text-align: center;
            margin-top: 5px;
        }

        .title {
            font-family: 'Open Sans Condensed', sans-serif;
            font-size: 0.9em;
            font-weight: 400;
            padding: 5px 15px 5px 15px;
            background-color: #06799f;
            color: #fff;
            margin: 1px;
            border-radius: 2px 2px 0 0;
        }

        .iw-content {
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
            margin-right: 1px;
            padding: 0 0 5px 0;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 95px;
        }

        p {
            margin: 10px 3px 0 3px;
        }
    }
}

.floatleft {
    float: left;
}

.floatright {
    float: right;
}

#eventsearch {
    background: lighten($greycolor, 55%);
    padding: 2%;

    label.checkbox {
        input {
            width: auto !important;
        }
    }

    .highlights {
        margin-bottom: 10px;
    }

    .all {
        margin-top: 0;
        margin-bottom: 5px;
        height: 40px;

        label {
            display: inline-block;
        }
    }

    .last {
        margin-right: 0px !important;
    }

    .submit {
        width: 33%;
        display: block;

        input {
            width: 93%;
            background: $bluecolor;
            color: $colorwhite;
            @include border-radius($radius);
            margin: 20px 0 5px 0;
            border: transparent;

            &:hover {
                background: lighten($bluecolor, 5%);
            }

            &:focus {
                @include boxshadow(0px 0px 6px 0px rgba(0,0,0,0.30));
                background: lighten($bluecolor, 10%);
            }
        }
    }

    .location {
        width: 30% !important;
    }

    .inputblock {
        width: 28%;
        display: block;
        margin-bottom: 25px;
        margin-right: 2%;
        position: relative;

        &:first-child {
            margin-right: 0;

            input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: 0;
            }
        }

        .fa-calendar:before {
            @include arrowfont;
            font-size: 1.45em;
            position: absolute;
            width: 24px;
            color: $greycolor;
            margin: auto;
            right: 0;
            top: 50%;
            font-weight: 200;
        }

        .fa-calendar:hover:before {
            color: darken($colorgrey, 25%);
        }

        input {
            width: 94%;
            height: 15px;
        }
    }

    .selectfields {
        display: none;
    }

    .rangeblock {
        clear: both;

        label {
            padding-bottom: 10px;
        }

        #rangeslider {
            font-size: 1.4em;

            span {
                outline: none;
            }

            .ui-state-default {
                background: $bluecolor;
                border: solid 1px transparent;

                &:hover {
                    background: lighten($bluecolor, 5%);
                    cursor: pointer;
                }
            }

            .ui-state-active {
                @include boxshadow;
                background: lighten($bluecolor, 5%);
            }
        }
    }

    .locationselect {
        width: 40%;
        float: left;
        margin-right: 2% !important;

        label {
            margin-bottom: 0;
        }

        select {
            width: 100%;
            padding: 8px 0 8px 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .select {
            width: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            @include whitegradient;
            height: 35px;
        }


        .focus {
            @include whitegradientfocus;
        }
    }

    .radius {
        width: 10%;
        float: left;
        margin-right: 2%;

        .value {
            padding: 9px 10px;
        }

        label {
            width: 100%;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        select {
            width: 100%;
            padding: 8px 0 8px 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .select {
            width: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            @include whitegradient;
            height: 35px;
        }

        .focus {
            @include whitegradientfocus;
        }
    }

    fieldset {
        display: block;
        width: 100%;

		.checkboxes::after {
			content: "";
			clear: both;
			display: block;
		}

        &:last-child {
            float: none;
        }

        label {
            display: block;
            float: left;
            width: 32%;
        }
    }
}

.eventlist {
    border-bottom: solid 1px lighten($colorgrey, 15%);
    padding: 1.5em 0 1.5em 0;
    display: block;

	&:last-of-type {
		border-bottom: none;
	}

    .events__result-hdl {
		margin: 0 0 .5em 0;
		font-size: 1.85em;

		span {
			border: 0;
		}
    }

    .subtitle {
        span {
            font-size: 0.9em;
        }
    }

    @include clearfix;

    .teaser {
        width: 182px;
        position: relative;
        float: left;
        margin: 0 2% 0 0;
        overflow: hidden;

        span {
            width: 100%;
            /*max-height: 100px;*/
        }

        img {
            width: 100%;
        }
    }

    .contents {
        position: relative;
        @include clearfix;

        p {
            width: 95%;
        }

        .eventbutton {
            right: 0;
            bottom: 0;
            font-size: 0.9em;
            padding: 3px 8px;
            color: $colorwhite;
            background-color:#C61F00;
            @include border-radius($radius);
            float: right;

            &:hover {
                background-color: darken(#C61F00, 5%);
            }

			&:focus {
				padding: 1px 6px;
				color: black;
			}
        }
    }
}

/* Start Event-Details*/

.eventdetails .teasertext li {
    list-style: inside;
}

.teasertext .content {
    ul, ol {
        overflow: hidden;
    }
}

.teasertext {
    @include clearfix;
}

.ticketanchor {
    background: #eee;
    display: inline-block;
    padding: 2px 5px 2px 5px;
    float: right;
}

.eventdate, .rmvinfos {
    padding-bottom: 10px;
    margin-bottom: 10px;

    .row {
        width: 100%;
        border-bottom: solid 1px lighten($greycolor, 40%);


        &:nth-child(odd) {
            background: lighten($colorgrey, 45%);
        }

        @include clearfix;

        &:first-child {
            border-top: solid 1px lighten($greycolor, 40%);
        }

        .head {
            font-weight: bold;
            float: left;
            width: 28%;
            display: block;
            padding: 8px 0 8px 2%;
        }

        .column {
            float: left;
            width: 28%;
            display: block;
            padding: 8px 0 8px 2%;

            a:hover {
                text-decoration: underline;
            }
        }
    }
}

.rmvinfos a {
    color: #aa2108;
    font-weight: bold;
}

.detailinfo {
    background: lighten($colorgrey, 43%);
    padding: 10px 5%;
    margin: 1em 0 2em 0;
    @include clearfix;

    h2 {
        font-size: 1.4em;
        font-weight: 500;
        padding-bottom: 8px;
        border-bottom: solid 1px lighten($greycolor, 40%);
    }

    .booking, .organizer {
        @include clearfix;
    }

    .inside {
        width: 45%;
    }

    .tickets {

        .fa {
            &:before {
                font-size: 1.2em;
                line-height: 1.4;
                min-width: 25px;
                display: block;
                float: left;
            }

            a {
                font: 1.1em/1.5 "Arial", sans-serif;
            }
        }

        .fa-phone span {
            font: 1.1em/1.5 "Arial", sans-serif;
        }
    }
}

.teasertop {
    width: 41%;
    overflow: hidden;
    max-height: 300px;
    float: right;
    margin-left: 2%;

    .img-border {

        &:hover img {
        }

        &:before {
            box-shadow: inset 0 0 0 4px rgba(0,0,0,0);
        }

        img {
            width: 100%;
        }
    }
}

.teaserleft {
    width: 35%;
    overflow: hidden;
    max-height: 250px;
    float: left;
    margin-right: 10px;

    .img-border {
        &:before {
            box-shadow: inset 0 0 0 4px rgba(0,0,0,0);
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}

.teaserright {
    width: 35%;
    overflow: hidden;
    max-height: 250px;
    float: right;
    margin-left: 15px;

    .img-border {
        &:before {
            box-shadow: inset 0 0 0 4px rgba(0,0,0,0);
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}

.back {
    margin-top: 18px;
    /*font-family: FontAwesome;*/
    font-family:"Arial", sans-serif; 

    .linkback {
        font-size: 1em;
        font-family: "Arial", sans-serif;

        &:hover {
            text-decoration: underline;
        }
    }
}

.table-scrollable, .table-max-2-columns {
    a {
        color: #aa2108;
        font-weight: bold;
    }

    td {
        padding: 0 5px;

        p {
            margin: 0.5em;
        }
    }
}

@media (max-width: 660px) {


    #eventsearch {

        .ui-accordion .ui-accordion-content {
            padding: 0;
            border-top: 0;
            overflow: hidden !important;
            border-color: lighten($greycolor, 48%);
        }

        .ui-accordion .ui-accordion-header {
            display: block;
            cursor: pointer;
            position: relative;
            margin: 8px 0 0 0;
            min-height: 20px;
            padding: 8px 0 5px 30px;
            background: lighten($greycolor, 58%);
            border-color: lighten($greycolor, 48%);
        }

        .ui-accordion-header:before {
            content: '\f067';
            @include toggle-arrow-events;
        }

        .ui-accordion-header-active:before {
            content: '\f068';
            @include toggle-arrow-events;
        }

        form {

            .inputblock {
                width: 48%;

                &:first-child {
                   // width: 73% !important;

                    input {
                        width: 94%;
                    }
                }
            }

            .radius {
                width: 25%;
                float: left;
                margin-right: 0;

                select {
                    border-left: solid 1px #ccc !important;
                }

                .select {
                    border-left: solid 1px #ccc !important;
                    height: 35px;
                }

                .value {
                    text-align: center;
                    padding-top: 8px;
                }
            }

            .all, .highlights {
                width: 100%;
                overflow: hidden;

                label {
                    display: block;
                    width: 100%;
                    background: #fff;
                    margin: 0;
                    padding: 2%;
                    border-bottom: solid 1px lighten($colorgrey, 40%);

                    &:before {
                        content: "";
                        background: url("../images/input.png") 0 0 no-repeat;
                        width: 24px;
                        height: 20px;
                        display: block;
                        float: left;
                    }

                    input {
                        display: none;
                    }

                    &:hover {
                        background: lighten($colorgrey, 40%);
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }

                .checked {
                    background-color: lighten($colorgrey, 50%);

                    &:before {
                        content: "";
                        background: url("../images/input.png") 0 -19px no-repeat;
                        width: 24px;
                        height: 20px;
                        display: block;
                        float: left;
                    }
                }
            }

            fieldset {
                display: block;
                width: 100%;

                h3 {
                    display: block !important;
                }

                &:last-child {
                    float: none;
                }

                label {
                    display: block;
                    width: 100%;
                    background: #fff;
                    margin: 0;
                    padding: 2%;
                    border-bottom: solid 1px lighten($colorgrey, 40%);
                    font-size: 0.9em !important;

                    &:before {
                        content: "";
                        background: url("../images/input.png") 0 0 no-repeat;
                        width: 24px;
                        height: 20px;
                        display: block;
                        float: left;
                    }

                    input {
                        display: none;
                    }

                    &:hover {
                        background: lighten($colorgrey, 40%);
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }

                .checked {
                    background-color: lighten($colorgrey, 50%);

                    &:before {
                        content: "";
                        background: url("../images/input.png") 0 -19px no-repeat;
                        width: 24px;
                        height: 20px;
                        display: block;
                        float: left;
                    }
                }
            }
        }
    }

    /*Details eventdates and RMV-Information*/

    .eventdate, .rmvinfos {
        .row {

            &:nth-child(odd) {
            }

            &:first-child {
            }

            .head {
                width: 35%;
            }

            .column {
                width: 35%;

                a:hover {
                }
            }
        }
    }

    .teasertop {

        .img-border {

            &:hover img {
                margin-top: 0;
            }
        }
    }

    .table-scrollable {
        width: 100%;
        overflow-y: auto;
        _overflow: auto;
        margin: 0 0 1em;

        p {
            margin: 0;
        }

        td {
            padding: 3px;
        }
    }

    .table-max-2-columns {

        p {
            margin: 0;
        }

        td {
            padding: 3px;
        }

        thead {
            th {
                padding: 10px 15px;

                &:first-child {
                }

                &:last-child {
                    background: whitesmoke;
                    border-bottom: 0;
                    font-weight: 300;
                }
            }
        }

        td, th {
            display: block;
        }

        tbody {
            th {
                margin-top: 15px;
            }
        }
    }
}

@media (max-width: 540px) {

    .detailinfo {
        padding: 10px 5%;

        h2 {
            font-size: 1.4em;
            font-weight: bold;
            padding-bottom: 8px;
            border-bottom: solid 1px lighten($greycolor, 40%);
        }

        .inside {
            width: 100%;
        }
    }
}

@media (max-width: 480px) {

    .blindspot {
        position: absolute;
        font-size: 4em;
        top: 0;
        display: block;
        height: 70%;
        width: 35px;
        right: 0;
        opacity: 0.0;
        background: #fff;
    }

    #eventsearch {
        form {

            .date {
            }

            .inputblock {
                width: 48%;
                display: block;
                margin-bottom: 25px;
                margin-right: 2%;
                position: relative;
            }
        }
    }

    .eventlist {
        border-bottom: solid 1px lighten($colorgrey, 15%);
        padding: 0 0 25px 0;
        display: block;

        .headline {

            h2 {
                font-size: 1.85em;
                margin-bottom: 5px;
            }
        }

        .subtitle {
            span {
                font-size: 0.9em;
            }
        }

         


        @include clearfix;

        .teaser {
            width: 100%;
            position: relative;
            float: left;
            margin: 0 2% 15px 0;
            max-height: 160px;
            overflow: hidden;

            span {
                width: 100%;
                max-height: none;
            }

            img {
                width: 100%;
                margin-top: -25px;
            }
        }

        .contents {

            p {
                width: 100%;
            }
        }
    }




    .teaserleft, .teaserright, .teasertop {
        width: 100%;
        overflow: hidden;
        max-height: 250px;
        float: none;

        .img-border {
            &:before {
                box-shadow: inset 0 0 0 4px rgba(0,0,0,0);
            }

            img {
                width: 100%;
                height: auto;
                margin: 0;
            }
        }
    }

    .eventdate, .rmvinfos {
        .row {

            &:nth-child(odd) {
            }

            &:first-child {
            }

            .head {
                width: 45%;
            }

            .column {
                width: 45%;

                a:hover {
                }
            }
        }
    }
}

//## Event-Calendar END


//## Highlights-Slider START

.h-slider-wrapper {
    margin-top: 10px;

    .h-slider__hdl {
        float: left;
        margin-top: 0;
    }


    .h-slider__linkwrap {
        float: right;
        padding-bottom: 10px;
        padding-top: 20px;

        &:before {
            margin-right: 2px;
        }
    }

    .h-slider__link {

        &:hover {
            text-decoration: underline;
        }
    }
}

#h-slider {
    @include clearfix;
    width: 100%;
    background: lighten($colorgrey, 45%);
    max-height: 224px;
    overflow: hidden;
    position: relative;


    span.h-slider-down, span.h-slider-up {
        font-size: 1.6em;
        cursor: pointer;
        width: 43%;
        padding: 0;
        right: 0;
        position: absolute;
        padding: 1px 0 3px 0;
        text-align: center;
        opacity: 0;
        @include transition;
    }

    .h-slider-down {
        bottom: 0;
    }

    &:hover .h-slider-up, &:hover .h-slider-down {
        opacity: 100;
        @include transition;
    }

    .highlights {
        float: left;
        width: 57%;
        height: 100%;
        min-height: 224px;

        .highlight {
            position: relative;
            overflow: hidden;
            height: 224px;

            .info {
                background: rgba(0,0,0,0.5);
                display: block;
                position: absolute;
                width: 100%;
                bottom: 0;
                color: #fff;
                padding-left: 10px;
                padding-right: 10px;
                box-sizing: border-box;

                p {
                    margin-top: 3px;
                    margin-bottom: 3px;
                }

                h2 {
                    font-weight: 600;
                    font-size: 1.2em;
                    color: #fff;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }

            .h-teaser {
                img {
                    width: 100%;
                    min-height: 224px;
                }
            }

			.button-highlight {
				.slider-button-div {
					float: right;
					color: #fff; 
					background-color: rgb(198, 31, 0);
					background-image: none;
					background-repeat: repeat; 
					background-attachment: scroll; 
					background-clip: border-box; 
					background-origin: padding-box;
					background-position-x: 0%; 
					background-position-y: 0%; 
					background-size: auto auto;
					margin-bottom: 0.8em;
					padding: 8px 3%;
					border: 1px solid #ccc;
					border-radius: 2px;
				}
			}
        }
    }

    .thumbs {
        width: 43%;
        float: left;
        @include clearfix;
        position: relative;

        .current {
            background: lighten($colorgrey, 40%);
        }

        a {
            display: block;

            &:not([role="button"]):focus {
                color: #000 !important;
            }

            &:last-child .inside {
                border-bottom: none;
            }

            .inside {
                padding: 19px 0 19px 0;
                @include clearfix;
                margin: 0 2%;
                border-bottom: solid 1px lighten($colorgrey, 25%);

                .h-thumbteaser {
                    width: 20%;
                    float: left;
                    margin-right: 2%;
                    margin-left: 2%;
                    height: 33px;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }
                }

                .thumbtext {
                    > h3 {
                        margin: 0;
                        font-size: 1.1em;
                        font-weight: 600;
                    }

					> h3,
					> strong {
						display: block;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

                    p {
                        font-size: 0.9em;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.hide {
    display: none;
}

.show {
    display: block;
}

@media (max-width: 660px) {

    .h-slider-wrapper {

    .h-slider__hdl {
        float: none;
    }


    .h-slider__linkwrap {
        float: none;
        padding-bottom: 10px;
        padding-top: 20px;

    }

}

    .h-slider-up, .h-slider-down {
        display: none;
    }

    #h-slider {
        width: 100%;
        overflow: inherit;
        max-height: none;

        .highlights {
            width: 100%;
            min-height: 0;

            .highlight {
                height: auto;

                img {
                    min-height: 0 !important;
                }
            }
        }

        .thumbs {
            width: 100%;
            @include clearfix;
            margin-top: 0px !important;


            .current {
                background: lighten($colorgrey, 40%);
            }
        }
    }
}

//## Highlights-Slider END


.main a:not([role="button"]):focus {
    color: inherit !important;
}



.related-content .ui-widget {
    font-family: "Arial", sans-serif;
    font-size: inherit;

    .ui-accordion-header {
        background: darken(whitesmoke, 10%);
        border: 0;
        border-radius: 0;
        margin: 5px 0 0 0;

        &:before {
            content: '\f024';
            position: absolute;
            z-index: 2;
            width: 24px;
            height: 17px;
            font-family: FontAwesome;
            color: #646464;
            margin: auto;
            top: 0;
            bottom: 0;
            right: 10px;
            font-size: 1.2em;
        }

        &:hover {
            background: darken(whitesmoke, 12%);
        }
    }

    .ui-accordion-header-active {
        background: darken(whitesmoke, 12%);
        border: 0;
        border-radius: 0;

        &:before {
            content: '\f024';
            position: absolute;
            z-index: 2;
            width: 24px;
            height: 17px;
            font-family: FontAwesome;
            color: #fff;
            margin: auto;
            top: 0;
            bottom: 0;
            right: 10px;
            font-size: 1.2em;
        }

        &:hover {
            background: darken(whitesmoke, 15%);
        }
    }

    .ui-widget-content {
        border: 0;
        background: whitesmoke;
        padding: 2%;
        margin-top: 0;
    }

    .related-content-post {
        min-height: 0;
    }
}


//######################
//## Hessenfinder
//######################


//## Tabs

.color-red ul.tabs {
    a.selected {
        color: #555;
        border: 1px solid #ddd;
        border-top: 2px solid $color-living;
        border-bottom: 1px solid #fff;
		background: transparent; /* initial supported from IE12, not IE11! */
        background: initial;
        padding: 8px 10px 8px 10px;
    }

    &.living {
        a.selected {
            border-top: 2px solid $mtk-red;
        }
    }

    &.area {
        a.selected {
            border-top: 2px solid $mtk-blue;
        }
    }

    &.tourism {
        a.selected {
            border-top: 2px solid $apple-green;
        }
    }

    &.economy {
        a.selected {
            border-top: 2px solid $mtk-gray;
        }
    }
}

ul.tabs {
    @include clearfix;
    border-bottom: 1px solid #ddd;
    margin: 0;
    padding: 0;
    line-height: normal;

    li {
        margin: 0 1% 0 0;
        padding: 0;
        float: left;
        background: none;
        color: #000;
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;

        a {
            padding: 8px 10px 8px 10px;
            display: inline-block;
            margin: 0 0 -1px;
            font-weight: 600;
            text-align: center;
            color: #bbb;
            border: 1px solid transparent;

            &:hover {
                text-decoration: none;
                color: #000;
                //background: darken($color-tourism, 5%);
            }

            &:focus {
                color: #000;
            }
        }

        a.selected {
            padding-bottom: 0;
        }
    }
}

.hessenfinder_suche {
    padding: 2%;

    .error {
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;
        padding: 5px;
        min-width: 49%;
        display: inline-block;
        font-weight: 600;
    }

    .currentlocation {
        color: #31708f;
        background-color: #d9edf7;
        border-color: #bce8f1;
        padding: 5px;
        min-width: 49%;
        font-weight: 600;
        display: inline-block;
    }

    .inputfield {
        width: 50%;
        margin-bottom: 15px;

        label {
            display: block !important;
        }

        input {
            padding: 10px 2% 10px 2%;
            width: 96% !important;
        }

        input[type="submit"] {
        }

        select {
            width: 100%;
        }

        .select {
            width: 100%;
        }
    }

    .inputfield:focus .select select, .inputfield:active .select select {
        border-color: #000;
    }

    .buttons {
        margin-top: 10px;

        input {
            &:hover {
                background: whitesmoke;
            }

            &:focus, &:active {
                background: darken(whitesmoke, 5%);
                border-color: #ddd;
            }
        }
    }

    ul {
        list-style: none;

        li {
            margin: 0;
        }
    }

    .nav-AZ {
        @include clearfix;

        ul {

            li {
                float: left;
                margin: 0 2px 10px 0;

                a {
                    margin: 0 8px 0 0;
                    margin-left: -1px;
                    font-weight: bold;
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.hf-details-search {
    background: whitesmoke;
    margin-top: 20px;
}

.main {
	ul.external-links {
		> li {
			margin: 0 0 1em 0;
			display: block;

			&.externallink {
				&:before {
					font-family: FontAwesome;
					content: "\f08e";
					margin-right: 3px;
				}
			}
		}
	}
}

.hf-details {

    .definition-hdl {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 1.2em;
        font-weight: 600;
    }

    .definition-text {
        margin: 0;
    }

    a {
        color: #aa2108;
        font-weight: bold;

        &:hover {
            color: #aa2108;
            text-decoration: underline;
        }
    }

    .result-location {
        margin-top: 20px;

        table {
            tr:last-child td {
                border-bottom: 1px solid #fff;
            }

            .fa-download {
                span {
                    font-family: Arial, Helvetica, sans-serif;
                }
            }

            .secondary-block {
                border-top: 10px solid #fff;
            }
        }

        .hf-th-hdl {
			padding: 10px 15px;
			margin-bottom: 0;
			border-bottom: 2px solid #e5e5e5;
			background-color: whitesmoke;
			font-size: 1.3em;
			font-family: "Signika", sans-serif; 
			font-weight: bold;
        }
    }

    &.living {
        .result-location table tr:last-child td {
            border-bottom: 1px solid $mtk-red;
        }
    }

    &.area {
        .result-location table tr:last-child td {
            border-bottom: 1px solid $mtk-blue;
        }
    }

    &.tourism {
        .result-location table tr:last-child td {
            border-bottom: 1px solid $apple-green;
        }
    }

    &.economy {
        .result-location table tr:last-child td {
            border-bottom: 1px solid $mtk-gray;
        }
    }

    .ui-widget {
        font-size: 100%;
        font-family: "Arial", sans-serif;
    }

    .ui-accordion .ui-accordion-header {
        background: whitesmoke;
        border: 0;
        border-radius: 0;
        padding-left: 30px;
        font-size: 110%;

        &:before {
            content: '\f078';
            position: absolute;
            z-index: 2;
            width: 24px;
            height: 16px;
            margin-left: -30px;
            font-family: FontAwesome;
            color: #646464;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 10px;
        }
    }

    .ui-accordion .ui-accordion-header-active {

        &:before {
            content: '\f077';
            position: absolute;
            z-index: 2;
            width: 24px;
            height: 16px;
            margin-left: -30px;
            font-family: FontAwesome;
            color: #646464;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 10px;
        }
    }

    .ui-accordion .ui-accordion-content {
        padding: 0;
        border-top: 0;
        overflow: auto;
    }

    .ui-widget-content {
        border: 0;
        background: none;
        color: inherit;
    }

	table {
		th {
			border: solid 2px #fff;
			font-size: 1em;
		}
	}
}

.cat-tree {

    ul {
        list-style: none;
        margin: 10px 0 0 0;

        li {
            margin: 0 0 0 0;
            border-bottom: 1px solid #ddd;
            padding: 5px;
            text-decoration: none;

            .subtree {
                li {
                    border: 0;
                    padding: 5px 5px 5px 14px;
                    position: relative;

                    a {
                        margin-left: 14px;
                    }

                    i {
                        font-size: 0.8em;
                        position: absolute;
                        top: 32%;
                    }

                    &:nth-child(odd) {
                        background: none;
                    }

                    &:first-child {
                        border-top: 0;
                    }
                }
            }

            a {
                &:nth-child(1) {
                    color: initial;
                }

                &:nth-child(2) {
                    font-weight: bold;
                    color: #aa2108;
                }
            }

            &:first-child {
                border-top: 1px solid #ddd;
            }

            &:nth-child(odd) {
                background: whitesmoke;
            }
        }

        .active {
            ul {
                margin-top: 0;
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }
    }
}


.search-results {
    &.hf-search {
        margin-top: 10px;
    }

    .hf-picking {
        font-weight: 600;
        color: #3c763d;
        background-color: #dff0d8;
        border-color: #d6e9c6;
        padding: 1em 10px;
    }

	.hf-picking > p {
		margin: 0;
	}

    .hf-error {
        font-weight: 600;
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;
        padding: 10px;
    }


    .hf-cat-choice {
		margin-top: 2em;
		/*
        font-size: 1.3em;
        font-weight: 600;
        margin: 0 0 10px 0;
        padding-left: 10px;
        margin-top: 20px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
		*/
    }


    .hf-infos {
        text-align: right;
        margin-top: 12px;

        .keyword {
            font-weight: 600;
        }

        .numberof {
            margin-top: 12px;
        }
    }

    .result-list {

        h3 {
            font-size: 1.3em;
            font-weight: 600;
            margin: 0 0 10px 0;
        }

        ul {
            list-style: none;
            margin: 0;

            li {
                margin: 0 0 0 0;

                a {
                    color: initial;
                    border-bottom: 1px solid #ddd;
                    padding: 10px;
                    display: block;
                    text-decoration: none;
                    font-weight: normal;

                    &:hover {
                        background: darken(whitesmoke, 3%);
                    }
                }

                &:first-child a {
                    border-top: 1px solid #ddd;
                }

                &:nth-child(odd) a {
                    background: whitesmoke;

                    &:hover {
                        background: darken(whitesmoke, 3%);
                    }
                }
            }
        }
    }
}


.hf-pagination {
    margin: 20px auto;
    text-align: center;

    ul {
        display: inline-block;
        margin: 0;

        li {
            margin: 0;
            float: left;
            list-style: none;

            a {
                padding: 8px 14px;
                color: #000;
                transition: background-color .4s;

                &:hover {
                    color: #000;
                    background: #ddd;
                    text-decoration: none;
                }

                &:first-child {
                    line-height: 2.5em;
                }
            }

            a.active {
                background: #06799f;
                color: #fff;
            }
        }
    }
}

@media (max-width: 660px) {

    ul.tabs {
        li {
            &:nth-of-type(2) {
                span {
                    display: none;
                }

                a {
                    &:before {
                        content: "Kategorien";
                    }
                }
            }

            &:nth-of-type(3) {
                span {
                    display: none;
                }

                a {
                    &:before {
                        content: "A - Z";
                    }
                }
            }
        }
    }

    #schoolnav ul.tabs {
        li {
            &:nth-of-type(2) {


                a {
                    &:before {
                        display: none;
                    }
                }
            }

            &:nth-of-type(3) {


                a {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }


    .hessenfinder_suche {

        .inputfield {
            width: 100%;
        }

        .error, .currentlocation {
            width: 98%;
        }
    }

    .nav-AZ {

        ul {

            li {


                a {
                    margin: 0 0 3px 0;
                    margin-left: -1px;
                    color: #337ab7;
                    text-decoration: none;
                    background: #fff;
                    border: 1px solid #eee;
                    padding: 3px 0 3px 0;
                    width: 25px;
                    display: block;
                    text-align: center;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    //## Hessenfinder Details
    .hf-details {
        .result-location {

            table {
                tr {
                    margin-bottom: 20px;

                    td {
                        display: block;
                        border: solid 1px #fff;

                        &:first-child {
                            font-weight: 600;
                        }

                        &:last-child {
                            margin-bottom: 20px;
                        }
                    }
                }

                tr:last-child td {
                    border-bottom: 1px solid #fff;
                }
            }
        }
    }
}

//## Tab-Content

.tab-content {
    &:nth-of-type(1), &:nth-of-type(3) {
        .hessenfinder_suche {
            background: whitesmoke;
        }
    }
}

@media (min-width: 662px) {
    #schoolnav .submit {
        position: relative;
        right: 0;
        bottom: 13px;
    }
}

//## Ehernamt

.col1.post {
    margin: 0 !important;
    h3{
        margin: 0.5em 0;
    }
}

.jsCopyRightBox {
	position: relative;
	cursor: pointer;
	display: inline-block;
	width: 100%;
	height: 100%;
	z-index: 100; 
}

.jsCopyRightBox div.inner {
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.8);
	padding: 4px;
	visibility: hidden;
	border: 0 !important;
	color:#000000 !important;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.jsCopyRightBox div.inner.active {
	visibility: visible; 
}

@media (max-width: 800px) {
	.jsCopyRightBox div.inner {
		visibility: visible !important; 
	} 
}

.show-for-sr {
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: 0 !important;
	overflow: hidden !important;
	position: absolute !important;
	clip: rect(0, 0, 0, 0) !important;
}

#lightbox {
	.lb-prev,
	.lb-next {
		&:focus {
			opacity: 1;
		}
	}

	.lb-prev:focus {
		background: url(../images/prev.png) left 48% no-repeat, linear-gradient(to right, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0));
	}

	.lb-next:focus {
		background: url(../images/next.png) right 48% no-repeat, linear-gradient(to left, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0));
	}

	.lb-data {
		.lb-close {
			width: 27px;
			height: 27px;

			&:focus {
				background-color: rgba(0, 0, 0, .3);
			}
		}
	}
}

#search::-webkit-input-placeholder {
	opacity: 1;
	color: #5f5f5f;
}

#search::-moz-placeholder {
	opacity: 1;
	color: #5f5f5f;
}

#search::-ms-placeholder {
	opacity: 1;
	color: #5f5f5f;
}

#search::placeholder {
	opacity: 1;
	color: #5f5f5f;
}

#searchsubmit {
	border-radius: 0;

	&:focus {
		background-color: #949494;
		color: #fff;
	}
}

.breadcrumb {
	background-color: #f2f2f2;

	p {
		color: #5a5a5a;

		a {
			color: #262626;

			&:hover {
				color: #5a5a5a;
			}
		}
	}
}

.slider-pager {
	a {
		-webkit-filter: brightness(80%);
		-moz-filter: brightness(80%);
		-ms-filter: brightness(80%);
		-o-filter: brightness(80%);
		filter: brightness(80%);
	}
}

body.tab-mode {
	:focus {
		outline: 3px solid #79a3eb !important;
		outline-offset: 2px !important;
		-webkit-transition: none !important;
		-moz-transition: none !important;
		-ms-transition: none !important;
		-o-transition: none !important;
		transition: none !important;
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-ms-animation: none !important;
		-o-animation: none !important;
		animation: none !important;
	}
}

.social.colored {
    .facebook-icon {
      color: #3b5998;
      
      &:hover {
        color: #4c74c7;
      }
    }
    .twitter-icon {
      color: #000000;
      
      &:hover {
        color: #4c4c4c
      }
    }
    .xing-icon {
      color: #1A7576;
      
      &:hover {
        color: #1e8687;
      }
    }
    .youtube-icon {
      color: #bb0000;
      
      &:hover {
        color: #e20000;
      }
    }
    .instagram-icon {
      color: #517fa4;
      
      &:hover {
        color: #679ac3;
      }
    }
}

@import "inc/mixins/fontawesome";
@import "inc/blocks/timeline";
